import style from "../../../../styles/setting.module.css";
const SetTab4 = () => {
  return (
    <>
      <div className={style.tabcontainer}>
        <div className={style.tabheader}>Administration</div>
        <div className={style.tab4container}>
          <textarea placeholder="Process to maintain client confidentiality"></textarea>

          <textarea placeholder="What process do you use to follow timely contractor required submissions (i.e., invoices, reports…)style"></textarea>
        </div>
        <div className={style.formbutton}>
          <button>Update</button>
        </div>
      </div>
    </>
  );
};

export default SetTab4;
