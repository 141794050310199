import styles from "../styles/SignUp.module.css";
import { AccountCircle, Email, Lock } from "@mui/icons-material";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { SignUpApi } from "../Api";
import { UserType } from "../@types/user";
import { useDispatch } from "react-redux";
import { login } from "../redux/userSlice";
import NotAuthenticatedLayout from "../components/layouts/NotAuthenticatedLayout";
import AuthWrapper from "../components/layouts/authWrapper";
import { notifyError, notifySuccess } from "../Notify";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";


const Signup = () => {
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const [activeSignUp, setActiveSignUp] = useState(1);
  const role = activeSignUp;

  const validationSchema = yup.object({
    name: yup.string().required('Name is Missing'),
    email: yup.string().email('Email is in Invalid Fromat').required('Email is Missing'),
    password: yup.string().required('Password is required')
      .matches(/(?=.*[a-z])/, 'Password must include at least one lowercase letter')
      .matches(/(?=.*[A-Z])/, 'Password must include at least one uppercase letter')
      .matches(/(?=.*\d)/, 'Password must include at least one number')
      .matches(/(?=.*[@$!%*?&])/, 'Password must include at least one special character')
      .min(8, 'Password must be at least 8 characters long')
      .max(50, 'Password must be at most 50 characters long'),
  })
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: (values: any) => {
      const { name, email, password } = values;
      SignUpApi(name, email, password, role)
        .then((result: any) => {
          let x: UserType = { ...result.data.user, id: result.data.id };
          dispatch(login(x));
          sessionStorage.setItem("user", JSON.stringify(x));
          notifySuccess(result.message);

        })
        .catch((error) => {
          notifyError(error.response.data.message);

        });
    },
  });



  const handleClick = (index: number) => {
    setActiveSignUp(index);
  };



  // const handleSignUp = async (e: React.FormEvent) => {
  //   e.preventDefault();


  // };
  // useEffect(() => {
  //   requrestPermission();
  // }, []);



  // async function requrestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     const Tok = await getToken(messaging, {
  //       vapidKey:
  //         "BKk19EeGEn5cCqbZwWAmVinfXoLk7a4uxZGe6PfUp5MFMS6j6_CM2fdVfo0bNlFksm_AQ6LZZWbiEcIKY_mZo4M",
  //     });
  //     console.log(Tok);
  //   } else if (permission === "denied") {
  //     alert("You denied for the notification");
  //   }
  // }


  return (
    <NotAuthenticatedLayout >
      <AuthWrapper
        heading="Sign Up"
        message="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      >
        <div className={styles.tabcontainer}>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => handleClick(1)}
              className={`${activeSignUp === 1 ? styles.coachbutton : null}`}
            >
              {activeSignUp === 1 ? (
                <p className={styles.coachbutton}>Coach</p>
              ) : (
                <p>Coach</p>
              )}
            </Button>
            <Button
              onClick={() => handleClick(2)}
              className={`${activeSignUp === 2 ? styles.coachbutton : null}`}
            >
              {activeSignUp === 2 ? (
                <p className={styles.coachbutton}>Coachee</p>
              ) : (
                <p>Coachee</p>
              )}
            </Button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="SignUp">
              <div className={styles.InputBox}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField

                    id="name"
                    name="name"
                    type="text"
                    label="Name"
                    sx={{ my: 0, width: "100%" }}
                    variant="standard"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}

                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle
                            sx={{ color: "#B5B5BE", mr: 0, my: 0.5 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />

                </Box>
              </div>
              <div className={styles.InputBox}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="email"
                    name="email"
                    type="text"
                    label="Email Address"
                    sx={{ m: 0, width: "100%" }}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <Email sx={{ color: "#B5B5BE", mr: 1, my: 0.5 }} />
                      ),
                    }}
                  />
                </Box>
              </div>
              <div className={styles.InputBox}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    label="Create Password"
                    sx={{ m: 0, width: "100%" }}
                    variant="standard"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                      endAdornment: (
                        <Lock sx={{ color: "#B5B5BE", mr: 1, my: 0.5 }} />
                      ),
                    }}
                  />
                </Box>
              </div>
            </div>

            <div>
              <button className={`${styles.btn} ${styles.btn1}`} type="submit">
                Create an account
              </button>
            </div>
            <p>-----------------Instant Signup-----------------</p>
            <div>
              <button className={`${styles.btn} ${styles.btn2}`}>
                <img src="/images/Coach/Google.png" />
                continue with google
              </button>
            </div>
            <div>
              <button className={`${styles.btn} ${styles.btn3}`}>
                <img src="/images/Coach/f-icon.png" />
                continue with Facebook
              </button>
            </div>
            <div>
              <button className={`${styles.btn} ${styles.btn1}`} onClick={()=>navigate("/login")}>
                Login
              </button>
            </div>
            <div>
              {/* <button className={`${styles.btn} ${styles.btn4}`}>
            <img src="/images/Coach/insta-icon.png" alt="" />
            continue with Instagram
          </button> */}
            </div>
          </form>
        </div>
      </AuthWrapper>
    </NotAuthenticatedLayout>
  );
};

export default Signup;
