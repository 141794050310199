import { useState, useEffect } from "react";
import style from "../../../../styles/setting.module.css";
import styles from "../../Coachee/CSS/dashboardHome.module.css";
import { Calendar as BigCalendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Modal, Input, Button } from 'antd';
import * as md from "@mui/material";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TodayIcon from '@mui/icons-material/Today';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { Box, Typography } from "@mui/material";
import { notifyError, notifySuccess } from "../../../../Notify";
import { getmanageAvail, manageAvail } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

type propType = {
  activeTab: number;
  setActiveTab: (val: number) => void;
  children: any;
};
interface TimeSlot {
  starttime: string;
  endtime: string;
}


interface AvailabilityData {
  day: string[];
  availtime: TimeSlot[]
}
type DateArray = Date[];
const localizer = momentLocalizer(moment);

interface Event {
  id: string;
  title: string;
  start: Date;
  end: Date;
}


const SetTab5 = ({ activeTab, setActiveTab, children }: propType) => {

  const [events, setEvents] = useState<Event[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [eventTitle, setEventTitle] = useState<string>('');

  const [arrayDate, setArrayDate] = useState<DateArray>([])
  const [Availdata, setAvailData] = useState<any[]>([])
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([
    { starttime: '', endtime: '' }
  ]);

  const [mon, setMon] = useState<any[]>([])
  const [tue, setTue] = useState<any[]>([])
  const [wed, setWed] = useState<any[]>([])
  const [thu, setThu] = useState<any[]>([])
  const [fri, setFri] = useState<any[]>([])
  const [sat, setSat] = useState<any[]>([])
  const [sun, setSun] = useState<any[]>([])

  const [day, setDay] = useState<string>('');
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [date, setDate] = useState('');
  const [updata, setUpdata] = useState(false)
  const handleClose = () => setOpen1(false);
  const handleClose1 = () => setOpen2(false);
  const handleClose2 = () => setOpen3(false);
  dayjs.extend(weekOfYear);
  dayjs.extend(isoWeek);

  const daysArray = Array.from({ length: 7 }, (_, i) => {
    const currentDay = dayjs().add(i + 1, 'day');
    return {
      day: currentDay.format('ddd'),
      date: currentDay.format('YYYY-MM-DD'),

    };

  });

  useEffect(() => {

    const daysArray = Array.from({ length: 7 }, (_, i) => {
      const currentDay = dayjs().add(i + 1, 'day');
      return {
        day: currentDay.format('ddd'),
        date: currentDay.format('YYYY-MM-DD'),

      };

    });
    const dates: any = daysArray.map(({ date }) => date);

    setArrayDate(dates);
    setUpdata(true)



  }, [arrayDate, cookie, updata]);


  const handleDates = async (e: any, day: any, date: any) => {
    setDay(day);
    setDate(date)
    e.preventDefault();
    setOpen1(true);
  };


  const convertTo12HourFormat = (timeStr: any) => {

    const [hours, minutes]: any = timeStr.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = ((hours % 12) || 12);
    const formattedMinutes: any = minutes;
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  const formattedTimeSlots = timeSlots.map(slot => ({
    starttime: convertTo12HourFormat(slot.starttime),
    endtime: convertTo12HourFormat(slot.endtime),
  }));
  const handleSubmitDate = (e: any) => {
    e.preventDefault();

    manageAvail(availabilityData, cookie)
      .then((res: any) => {
        notifySuccess(res.message)
        handleClose()
        setTimeSlots([{ starttime: '', endtime: '' }]);
        setUpdata(true)

      })
      .catch((err: any) => {
        notifyError(err)
        handleClose()
        setTimeSlots([{ starttime: '', endtime: '' }]);
      })
    setUpdata(false)

  }
  const handleOpenAvail = (e: any, data: any) => {
    e.preventDefault();
    setAvailData(data)
    setOpen2(true);
  }

  const [emptyabilitydata, setEmptyabilitydata] = useState<AvailabilityData>({
    day: [],
    availtime: []
  });

  const handleDelAvai = (e: any, day: string) => {
    e.preventDefault();
    setEmptyabilitydata({
      day: [day],
      availtime: []
    });

    setOpen3(true);
  };

  const deletetheData = (e: any) => {
    e.preventDefault();
    manageAvail(emptyabilitydata, cookie)
      .then((res: any) => {
        notifySuccess(res.message);
        handleClose2();
        setUpdata(true);
      })
      .catch((err: any) => {
        notifyError(err);

        handleClose2();
      });
    setUpdata(false);
  };
  const initialEvents: Event[] = [

    {
      id: '0',
      title: '',
      start: arrayDate[0],
      end: arrayDate[0],
    },
    {
      id: '1',
      title: '',
      start: arrayDate[1],
      end: arrayDate[1],
    },
    {
      id: '2',
      title: '',
      start: arrayDate[2],
      end: arrayDate[2],
    },
    {
      id: '3',
      title: '',
      start: arrayDate[3],
      end: arrayDate[3],
    },
    {
      id: '4',
      title: '',
      start: arrayDate[4],
      end: arrayDate[4],
    },
    {
      id: '5',
      title: '',
      start: arrayDate[5],
      end: arrayDate[5],
    },
    {
      id: '6',
      title: '',
      start: arrayDate[6],
      end: arrayDate[6],
    },
  ];

  useEffect(() => {
    setEvents(initialEvents);
    getmanageAvail(cookie)
      .then((res: any) => {
        console.log("result of data", res)
        setMon(res.data.mon)
        setTue(res.data.tue)
        setWed(res.data.wed)
        setThu(res.data.thu)
        setFri(res.data.fri)
        setSat(res.data.sat)
        setSun(res.data.sun)


      })
  }, [updata, cookie]);

  const handleTimeSlotChange = (index: number, field: 'starttime' | 'endtime', value: string) => {
    const updatedTimeSlots = [...timeSlots];
    updatedTimeSlots[index] = { ...updatedTimeSlots[index], [field]: value };
    setTimeSlots(updatedTimeSlots);

  };
  const addTimeSlot = () => {
    setTimeSlots([...timeSlots, { starttime: '', endtime: '' }]);
  }
  const removeTimeSlot = (index: number) => {
    const updatedTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(updatedTimeSlots);
  };
  const handleSelectSlot = ({ start }: { start: Date }) => {
    setSelectedDate(start);
    // setIsModalVisible(true);
  };

  const handleSave = () => {
    if (selectedDate) {
      const newEvent: Event = {
        id: Date.now().toString(),
        title: eventTitle,
        start: selectedDate,
        end: new Date(selectedDate.getTime() + 60 * 60 * 1000),
      };
      setEvents([...events, newEvent]);
      setIsModalVisible(false);
      setEventTitle('');
    }
  };

  const eventStyleGetter = (event: Event) => {
    const style = {
      backgroundColor: '#97cc4f',
      border: 'none',
      borderRadius: '50%',
      color: 'black',
      width: '10px',
      height: '10px',
      margin: '0px 0px 0px 40px ',
      display: 'inline-block',
      verticalAlign: 'middle',
    };
    return { style };
  };

  const EventComponent = ({ event }: { event: Event }) => (
    <div style={{ ...eventStyleGetter(event).style }} />
  );

  const handleCancel = () => {
    setIsModalVisible(false);
    setEventTitle('');
  };

  const isSameDay = (date1: Date, date2: Date) => {
    return date1.toDateString() === date2.toDateString();
  };



  const filteredEvents = selectedDate
    ? events.filter((event) =>
      isSameDay(new Date(event.start), selectedDate) || isSameDay(new Date(event.end), selectedDate)
    )
    : [];
  const availabilityData: AvailabilityData = {
    day: [day.toLowerCase()],
    availtime: formattedTimeSlots
  };


  return (
    <>
      <div className={style.BigCalendarContent}>
        <div className={style.BigCalendarLeft}>
          <BigCalendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            views={[Views.MONTH, Views.DAY]}
            defaultView={Views.MONTH}
            selectable
            onSelectSlot={handleSelectSlot}
            eventPropGetter={eventStyleGetter}
            components={{ event: EventComponent }}
            className={style.BigCalendar}
          />
          <Modal
            title="Schedule Appointment"
            visible={isModalVisible}
            onOk={handleSave}
            onCancel={handleCancel}
            okText="Ok"
            cancelText="Cancel"
          >
            {filteredEvents.length > 0 ? (
              filteredEvents.map((env: any) => (
                <span key={env.id}>
                  <ScheduleSendIcon /> {env.title} 9.00AM to 10.00 AM
                </span>
              ))
            ) : (
              <p>No events scheduled for this day</p>
            )}
          </Modal>
        </div>
        <div className={style.BigCalendarRight}>
          <div className={style.setAvail}>
            <h6>Set Availability</h6>

            <div className={style.setAvailContent}>
              <ul>
                {daysArray.map(({ day, date }, index) => (

                  <li key={index} >

                    <div className={style.DateAndDay} onClick={(e) => handleDates(e, day, date)}>
                      {day} <br /> {date.slice(8, 10)}
                    </div>

                    <p className={style.DateAndDayPara}>

                      {day === "Mon" ? (<>{mon.length > 0 ? (<p><p onClick={(e: any) => handleOpenAvail(e, mon)}><AccessTimeIcon /><>Show Availability  </></p> <span ><DeleteOutlineIcon onClick={(e: any) => handleDelAvai(e, "mon")} /></span></p>) : (<>Set Availability</>)}</>) :
                          day === "Tue" ? (<>{tue.length > 0 ? (<p><p onClick={(e: any) => handleOpenAvail(e, tue)}><AccessTimeIcon /><>Show Availability  </></p><span ><DeleteOutlineIcon onClick={(e: any) => handleDelAvai(e, "tue")} /></span></p>) : (<>Set Availability</>)}</>)
                        : day === "Wed" ? (<>{wed.length > 0 ? (<p ><p onClick={(e: any) => handleOpenAvail(e, wed)}><AccessTimeIcon /><>Show Availability  </></p> <span ><DeleteOutlineIcon onClick={(e: any) => handleDelAvai(e, "wed")} /></span></p>) : (<>Set Availability</>)}</>)
                        : day === "Thu" ? (<>{thu.length > 0 ? (<p><p onClick={(e: any) => handleOpenAvail(e, thu)}><AccessTimeIcon /><>Show Availability  </></p> <span ><DeleteOutlineIcon onClick={(e: any) => handleDelAvai(e, "thu")} /></span></p>) : (<>Set Availability</>)}</>)
                        : day === "Fri" ? (<>{fri.length > 0 ? (<p><p onClick={(e: any) => handleOpenAvail(e, fri)}><AccessTimeIcon /><>Show Availability  </></p> <span ><DeleteOutlineIcon onClick={(e: any) => handleDelAvai(e, "fri")} /></span></p>) : (<>Set Availability</>)}</>)
                        : day === "Sat" ? (<>{sat.length > 0 ? (<p><p onClick={(e: any) => handleOpenAvail(e, mon)}><AccessTimeIcon /><>Show Availability  </></p> <span ><DeleteOutlineIcon onClick={(e: any) => handleDelAvai(e, "sat")} /></span></p>) : (<>Set Availability</>)}</>)
                        : day === "Sun" ? (<>{sun.length > 0 ? (<p><p onClick={(e: any) => handleOpenAvail(e, sun)}><AccessTimeIcon /><>Show Availability  </></p> <span ><DeleteOutlineIcon onClick={(e: any) => handleDelAvai(e, "sun")} /></span></p>) : (<>Set Availability</>)}</>) : (<></>)}
                    </p>

                  </li>
                ))}
              </ul>
            </div>
          </div>
          <md.Modal
            open={open1}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={styles.BoxStyle}>
              <span onClick={handleClose} className={styles.HandleClose}>
                <CloseTwoToneIcon className={styles.HandleCloseIcon} />
              </span>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Set Availability
              </Typography>
              <Typography
                id="modal-modal-description"
                className={styles.typoDesc}
              >
                <form onSubmit={handleSubmitDate}>
                  <div className={style.UpgradeDiv}>
                    <p>
                      <p className={style.calendDayTime}>
                        <p><CalendarMonthIcon /> Day: {day}</p>
                        <p><TodayIcon /> Date: {date}</p>
                      </p>
                      <div className={style.SelectTime}>
                        <div className={style.SelectTimeContent}>
                          {timeSlots.map((slot, index) => (
                            <>
                              <div className={style.SelectTimeMainContent}>
                                <div className={style.selectTimeLeft}>
                                  <div className={style.SelectTimeContent}>

                                    <label>Start Time</label>
                                    <input type="time" value={slot.starttime}
                                      onChange={(e) => handleTimeSlotChange(index, 'starttime', e.target.value)} />
                                  </div>
                                  <div className={style.SelectTimeContent}>
                                    <label>End Time</label>
                                    <input type="time" value={slot.endtime}
                                      onChange={(e) => handleTimeSlotChange(index, 'endtime', e.target.value)} />
                                  </div>
                                </div>
                                <button type="button" onClick={() => removeTimeSlot(index)} className={style.addavlbtn1}>
                                  <RemoveCircleOutlineIcon className={style.addavlbtn}/>
                                </button>
                              </div>

                            </>
                          ))}


                        </div>
                      </div>
                      <div className={style.addTimeSlot}>
                        <button type="button" onClick={addTimeSlot}>
                          <AddCircleOutlineIcon className={style.addTimeSlotbtn}/>
                        </button></div>

                    </p>
                    <button type="submit" className={style.HandleSaveAvail}>Save</button>

                  </div>

                </form>
              </Typography>
            </Box>
          </md.Modal>
          {/*  Show the availabilty */}
          <md.Modal
            open={open2}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={styles.BoxStyle}>
              <span onClick={handleClose1} className={styles.HandleClose}>
                <CloseTwoToneIcon className={styles.HandleCloseIcon} />
              </span>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Your  Availability
              </Typography>
              <Typography
                id="modal-modal-description"
                className={styles.typoDesc}
              >
                {
                  Availdata.length > 0 ? (Availdata.map((days: any, index: any) => (
                    <>
                      <p className={style.Availdatatime}>Slot {index + 1} : {days.starttime}-{days.endtime}</p>
                    </>
                  ))) : (0)
                }
              </Typography>
            </Box>
          </md.Modal>

          {/*  Delete Avail */}
          <md.Modal
            open={open3}
            onClose={handleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={styles.BoxStyle}>
              <span onClick={handleClose2} className={styles.HandleClose}>
                <CloseTwoToneIcon className={styles.HandleCloseIcon} />
              </span>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Delete Availability
              </Typography>
              <Typography
                id="modal-modal-description"
                className={styles.typoDesc}
              >
                <div className={style.availshow}>
                  <h6> Are you sure to Delete Availability<DeleteOutlineIcon /> ??</h6>
                  <div className={style.availshowbtn}>
                    <button className={style.availshowbtnred} onClick={deletetheData}><DeleteOutlineIcon /> Yes</button>
                    <button onClick={handleClose2} className={style.availshowbtnblue}> X No</button>
                  </div>
                </div>
              </Typography>
            </Box>
          </md.Modal>
        </div>
      </div>
    </>
  );
};

export default SetTab5;
