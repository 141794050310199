import AddIcon from '@mui/icons-material/Add';
import styles from "../programs/programs.module.css";
import style from "../wrapper/wrapper.module.css";
import { useEffect, useState } from 'react';
import Modal from "@mui/material/Modal";
import { Box, TextField, Typography } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { fetchAgroupId } from '../../../../Api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
type propType = {

    pid:any;
  };
  const GroupInfo = ({ pid }: propType) => {
    const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
    const [open, setOpen] = useState(false);
    const handleOpen1 = () => setOpen(true);
    const handleClose1 = () => setOpen(false);
  const[sabout,setsabout]=useState("");
  const[sName,setsName]=useState("")
    const handleSession=()=>{}
const[groupTopic,setgroupTopic]=useState("")
const[createdAt,setcreatedAt]=useState("");
const[coachName,setCoachName]=useState<any>([]);
const[session,setSession]=useState<any[]>([])
const [profileImg, setprofileImg] = useState<any>([]);
    // fetchAgroupId
    useEffect(() => {
        fetchAgroupId(pid,cookie)
          .then((result: any) => {
            console.log("Data of Group By ID",result);
            setcreatedAt(result.data.group.createdAt)
            setgroupTopic(result.data.group.groupTopic);
            setprofileImg(result.data.group.coacheeIds);
            setCoachName(result.data.group.coachIds);
            setSession(result.data.session)
          })
          .catch((error: any) => {
            console.error("Error fetching coach data:", error);
            
          });
      }, [cookie])
return(
    <>
   <div className={styles.container}>     
       
    
    <div className={styles.GroupInfoContent}>
    <div className={styles.GroupInfoLeft}>
    <h6>{groupTopic}</h6>
    <p>{createdAt.substring(0,10)}</p>
    {profileImg? ( profileImg.map((profile:any)=>(profile.profileImg?( <img  src={`https://dxe2g9i1k0e2c.cloudfront.net/${profile.profileImg}`} className={styles.groupIc2}/>):
    ( <img src="/images/svg/Coach/setting/personal.svg" className={styles.groupIc2} />))) ):
                        (<></>                      
                          )}

  
    <br/>
    <h5>Group Sessions</h5>
    </div>
    <div >
      <div className={styles.GroupInfoRight}>
      <div className={styles.groupData}>
        <p>Assigned Coach</p>
      
      <span><p>{coachName.length>0?(coachName.map((cname:any)=>(cname.name.slice(0,2).toUpperCase()))):(1)}</p>{coachName.length>0?(coachName.map((cname:any)=>(cname.name))):(1)}</span>
       
      </div>
      <div className={styles.groupData}>
         <p>Total Sessions</p><span>{session.length}</span>
         </div>
         </div>
        <br/>
        <div className={styles.groupButton}>
        <button className={styles.EditRightBtn1} onClick={handleOpen1}><AddBoxSharpIcon/> Add New Session</button>
</div>
<Modal
              open={open}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose1} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                 <h3> Create New Session</h3>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={style.typoDesc}
                >
                   <div className={style.ProgramProp}>
                  <div className={style.onlinePro} >
                 
                <form className={style.programForm}  onSubmit={handleSession}>
                  <TextField
          type="text"
          id="outlined-basic"
          label="Session Topic"
          variant="outlined"
          name="sName"
         value={sName}
          onChange={(e: any) => setsName(e.target.value)}
          className={style.Inputfield}
          InputProps={{
            className: `${style.muiInput}`,
            
          }}
          sx={{
            my: 1,
            "& .MuiInputBase-input": {
              width: "100%",
              
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "#fff",
  },
          }}
        />
        
        <TextArea
          id="outlined-basic"
          placeholder="What are you going to learn"
          name="sabout"
             value={sabout}
          onChange={(e: any) => setsabout(e.target.value)}
          

          rows={5}
        />
         <TextField
          type="number"
          id="outlined-basic"
          label="Overall Cost"
          variant="outlined"
          name="duration"
        //     value={duration}
        //   onChange={(e: any) => setDuration(e.target.value)}
          className={style.Inputfield}
          InputProps={{
            className: `${styles.muiInput}`,
          }}
          sx={{
            
            my: 1,
            borderRadius: "20px",
            "& .MuiInputBase-input": {
              width: "100%",
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "#fff",
  },
          }}
        />

         <TextField
          type="number"
          id="outlined-basic"
          label="Expected Duration of Session"
          variant="outlined"
          name="duration"
        //     value={duration}
        //   onChange={(e: any) => setDuration(e.target.value)}
          className={style.Inputfield}
          InputProps={{
            className: `${styles.muiInput}`,
          }}
          sx={{
            
            my: 1,
            borderRadius: "20px",
            "& .MuiInputBase-input": {
              width: "100%",
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "#fff",
  },
          }}
        />

       
           <button  className={style.SaveButton}>
            Save
         
          </button>

          </form>
                    </div> 
                  
           
                   </div>
                </Typography>

                </Box>
                </Modal>
              
    </div>
    </div>
    <br/>
    <table>
        <tr>
            <th>SESSION TOPIC</th>
            <th>WHAT ARE WE GOING TO LEARN</th>
            <th>OVERALL COST</th>
            <th>SESSION DURATION</th>
            <th>DATE</th>
            <th>STATUS</th>
            <th></th>
        </tr>
        {session.length?(session.map((sc:any)=>(<>
        <tr>
          <td>{sc.sName}</td>
          <td className={styles.groupDataInfo}>{sc.sabout}</td>
          <td>Pending</td>
          <td>{sc.duration}</td>
          <td>{sc.sdate.slice(0,10)}</td>
          <td>Status </td>
        </tr>
        </>))):<tr><td colSpan={3}>Sessions not Added Yet!</td></tr>}
    </table>
   </div>
    </>
)
}
export default GroupInfo;