import { useEffect } from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

type Proptype={
    coachId:any
}
const ShowGroups=({coachId}:Proptype)=>{
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    useEffect(()=>{
        
    },[cookie])
  
    return (<>
    </>)
}
export default ShowGroups;