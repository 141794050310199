import { useState } from "react";
import Faq from "./Faq";
import Chat from "./Chat";
import style from "./faq.module.css";
import styles from "./chat.module.css";
const Support = () => {
  const [activeScreen, setActiveScreen] = useState(1);

  return (
    <>
      <div className={style.SupportContent}>
        <div className={style.IndexContainer}>
          <div className={style.FQAdisp}>FAQ's</div>
          {activeScreen === 2 ? (
            <>
              <div className={style.BackTo} onClick={() => setActiveScreen(1)}>
                <img src="./images/Coach/return.png" />
                <span> Back</span>
              </div>
            </>
          ) : (
            <>
              <div
                className={style.linkname}
                onClick={() => setActiveScreen(2)}
              >
                <img src="/images/svg/Coach/setting/chatbot.svg" />
                <span>Chat with Support</span>
              </div>
            </>
          )}
        </div>
        <div className={style.FaqContainer}>
          {activeScreen === 1 && <Faq />}
          {activeScreen === 2 && <Chat />}
        </div>
      </div>
    </>
  );
};

export default Support;
