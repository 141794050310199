import { useEffect, useState } from "react";
import { CreateSessionByCoachee, fetchCard, fetchCoachByCoachee, fetchCoachDataForCoachee } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { notifyError, notifySuccess } from "../../../../Notify";
import EmailIcon from '@mui/icons-material/Email';
import styles from "../CSS/dashboardHome.module.css";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { Box, Button, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddCardIcon from '@mui/icons-material/AddCard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  coachId: any;
  
};
const ShowCoach = ({ setActiveTab, coachId }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [comPow, setcomPow] = useState<string[]>([])
  const[cardId,setCardId]=useState("")
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const [cards,setCards]=useState<any[]>([]);
  const [mon, setMon] = useState<any[]>([])
  const [tue, setTue] = useState<any[]>([])
  const [wed, setWed] = useState<any[]>([])
  const [thu, setThu] = useState<any[]>([])
  const [fri, setFri] = useState<any[]>([])
  const [sat, setSat] = useState<any[]>([])
  const [sun, setSun] = useState<any[]>([])
  const [data, setData] = useState<any>({
    name: "",
    email: "",
    profile: "",
    timeZone: "",

  })
  
  useEffect(()=>{
    fetchCard(cookie)
    .then((result:any)=>{
      setCards(result.data)
      console.log("Card details",result)
      
    })
    .catch((error:any)=>{
      console.log("from Card",error)
    })
  },[cookie])
  useEffect(() => {
    fetchCoachDataForCoachee(cookie, coachId)
      .then((result: any) => {
        console.log("why this data ", result)
        setData({
          name: result.data.coach.uid.name,
          email: result.data.coach.uid.email,
          profile: result.data.coach.uid.profileImg,
          timeZone: result.data.coach.timeZone,
        })
        setcomPow(result.data.coach.commpower)
      })


      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        notifyError(error);
      });
  }, [cookie, coachId])
  // CreateSessionByCoachee
  const [Typo, setTypo] = useState(0)
   const appType=1;
const starttime="02:00 PM"
const endtime="02:30 PM"
const date="12-09-2024";
const gId="66bf1ea73fb9733abb3f8004"
const pId="66bf1ea73fb9733abb3f8004"
const handleCardVal = (event:any) => {

setCardId(event.target.value)
}
useEffect(()=>
  {
    fetchCoachByCoachee(cookie)
    .then((result: any) => {
    console.log("res data",result)
    // setMon(result.data.ava)
    // seCoachData(result.data.history)

    
    })
    .catch((error: any) => {
      console.error("Error fetching coach data:", error);
      notifyError(error);
    });
}, [cookie]);
const handleSubmit=(e:any)=>{
  e.preventDefault()
 
  CreateSessionByCoachee(coachId,starttime,endtime,appType,date,pId,gId,cookie)
  .then((result)=>{
    console.log(result)
    notifySuccess(`Congrates! Your session is planned on ${date}`)
    handleClose()
    setTypo(0)
  }).catch((err:any)=>{
    notifyError(err.response.data.message)
    handleClose()
    setTypo(0)
  })
    
 
}
  return (<>
    <div className={styles.dashcontainer}>
      <div className={styles.UpcomingContainer}>


        <div className={styles.recommendContainer}>
          <div className={styles.recommendLeft}>
            {data.profile ? (<img
              src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profile}`}
              className={styles.coachImg}
            />) : (
              <h4 className={styles.coachIcon}>{data.name.slice(0, 2).toUpperCase()}</h4>
            )}

            <span>
              <h5>{data.name}</h5>
              <p className={styles.dataIcoCoach}><span><EmailIcon /></span> {data.email}</p>
            </span>
          </div>
          <div className={styles.recommendRight}>
            <button className={styles.TypoButton} onClick={handleOpen}><span><EditCalendarIcon /></span>Schedule Appointment</button>

            <Modal
              open={open1}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleClose} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  {Typo===0 ?(<h5> Schedule Appointment</h5>):(<h5> Make Payment</h5>)}

                </Typography>
               
                {Typo === 0 && (<Typography
                  id="modal-modal-description"
                  className={styles.typoDesc}>
                     <p className={styles.widPara} >Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>

                  <div className={styles.widPara}>
                    {data.profile ? (<img
                      src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profile}`}
                      className={styles.coachImg}
                    />) : (
                      <h4 className={styles.coachIcon}>{data.name.slice(0, 2).toUpperCase()}</h4>
                    )}
                    <div className={styles.UpgradeText}>
                      <p>

                        <h5 className={styles.UpgradeText}>{data.name}</h5>
                        <p className={styles.dataIcoCoach}><EmailIcon /> {data.email}</p>
                      </p>
                      <b>{comPow.length > 0 ? (comPow.map((compow: any) => (<>
                        <p className={styles.comPowStyle}>
                          {compow.split(',').map((part: string, i: number) => (
                            <p key={i} className={styles.comPowStyles}>
                              {part.trim()}
                            </p>
                          ))}
                        </p>
                      </>))) : (<>No Data found!</>)}</b>

                    </div>

                  </div>
                  <div className={styles.coachTimeZone}>
                    <h6>Total Cost  </h6>
                    <p>$24</p>
                  </div>
                  <div className={styles.coachTimeZone}>
                    <h6>Coach TimeZone </h6>
                    <p>UTC+5:30</p>
                  </div>
                  <div className={styles.coachTimeZone}>
                    <h6> Available Slot</h6>
                    <p>Slot 1</p>
                  </div>
                  <button onClick={() => setTypo(() => 1)} className={styles.TypoButton}>Save</button>
                </Typography>)}
              
                {Typo === 1 && (<Typography id="modal-modal-description"
                  className={styles.typoDesc}>
                    <div className={styles.widPara}>
                    {data.profile ? (<img
                      src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profile}`}
                      className={styles.coachImg}
                    />) : (
                      <h4 className={styles.coachIcon}>{data.name.slice(0, 2).toUpperCase()}</h4>
                    )}
                    <div className={styles.UpgradeText}>
                      <p>

                        <h5 className={styles.UpgradeText}>{data.name}</h5>
                        <p className={styles.dataIcoCoach}><EmailIcon /> {data.email}</p>
                      </p>
                      <b>{comPow.length > 0 ? (comPow.map((compow: any) => (<>
                        <p className={styles.comPowStyle}>
                          {compow.split(',').map((part: string, i: number) => (
                            <p key={i} className={styles.comPowStyles}>
                              {part.trim()}
                            </p>
                          ))}
                        </p>
                      </>))) : (<>No Data found!</>)}</b>

                    </div>

                  </div>
                  <form >
                  <h6 className={styles.dataColors}>Selected Sort </h6>
                 <div className={styles.comPowDesign}>
                 
                 <p className={styles.comPowStyles}>{starttime}-{endtime}</p>
                 <p className={styles.comPowStyles}>{date}</p>
                 </div>
                 <div>

                 </div>
                 <div>
                 <p> <b className={styles.dataColors}><AddCardIcon/> Select Payment Card</b></p>
              
                 <Select
      onChange={handleCardVal}
      className={styles.cardDesignSelect}
      labelId="demo-simple-select-standard-label"
      id="demo-simple-select-standard"
      label="Select Card"
    >
      {cards && cards.length > 0 ? (
        cards.map(cinfo => (
          <MenuItem key={cinfo.id} value={cinfo.id} className={styles.MenuItems}>
            <div className={styles.MenuItems}>
              <p><CreditCardIcon /> John Doe</p>
              <p className={styles.MenuItemsIC}>
                <FormatListNumberedIcon className={styles.MenuItemsIC} />
                {' '}.... .... .... {cinfo.last4}
              </p>
            
            </div>
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>
          <p className={styles.dataColors}>Please Add Cards.. Go To Settings!</p>
        </MenuItem>
      )}
    </Select>
    <br/>
                 </div>
                 <br/>
                   <button  className={styles.TypoButton} onClick={handleSubmit}>Pay Now</button>
</form>
                </Typography>)}
        

              </Box>
            </Modal>
          </div>
          
        </div>
        
        <hr />
        <div>
          <b>About Coach</b>

          <p className={styles.dataColors}>
            Lorem Ipsum is simply dummy text of the printing and type setting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
            when an unknown printer took a galley of type and scrambled it to make a type 
            specimen book. It has survived not only five centuries, but also the leap into 
            electronic typesetting, remaining essentially unchanged. It was popularised in the 
            1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more 
            recently with desktop publishing software like Aldus PageMaker including versions of 
            Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and type setting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an 
            unknown printer took a galley of type and scrambled it to make a type specimen book.
          </p>
        </div>
        
        <div>
          <br />
          <b>Categories that can help</b>
          <b>{comPow.length > 0 ? (comPow.map((compow: any) => (<>
            <p className={styles.comPowStyle}>
              {compow.split(',').map((part: string, i: number) => (
                <p key={i} className={styles.comPowStyles}>
                  {part.trim()}
                </p>
              ))}
            </p>
          </>))) : (<>No Data found!</>)}</b>

        </div>
        
      </div>
      
    </div>
  </>)
}
export default ShowCoach;