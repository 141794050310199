import NotAuthenticatedLayout from "../components/layouts/NotAuthenticatedLayout";
import AuthWrapper from "../components/layouts/authWrapper";
import { useState } from "react";
import Main from "../components/forget-password/Main";
import Verify from "../components/verify";
import ResetPassword from "../components/reset-password";
import PasswordResetsuccess from "../components/reset-password/success";
import { useLocation, useParams } from "react-router-dom";

const ForgotPassword = () => {
  const location = useLocation();

  let initialMailSubmitted = false;
  let initialVerified = false;
  let initialResetSuccess = false;
  let type: any;

  if (location.pathname === "/verify") {
    initialMailSubmitted = true;
    initialVerified = false;
    initialResetSuccess = false;
    type = "Create Account";
  }

  if (location.pathname === "/forget-password") {
    type = "forget";
  }
  const [mailSubmitted, setMailsubmitted] = useState(initialMailSubmitted);
  const [verified, setVefified] = useState(initialVerified);
  const [resetsuccess, setResetSuccess] = useState(initialResetSuccess);

  return (
    <>
      <AuthWrapper
        heading="Verify Your Account"
        message="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      >
        <>
          {!mailSubmitted && !verified && !resetsuccess && (
            <Main setMailsubmitted={setMailsubmitted} />
          )}
          {mailSubmitted && !verified && !resetsuccess && (
            <Verify setVefified={setVefified} type={type} />
          )}

          {mailSubmitted && verified && !resetsuccess && (
            <ResetPassword setResetSuccess={setResetSuccess} />
          )}
          {mailSubmitted && verified && resetsuccess && (
            <PasswordResetsuccess />
          )}
        </>
      </AuthWrapper>
    </>
  );
};

export default ForgotPassword;
