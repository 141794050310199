import style from "../../../../styles/setting.module.css";

type propType = {
  activesetTab: number;
  setActivesetTab: (val: number) => void;
  children: any;
};
function MainSettings({ activesetTab, setActivesetTab, children }: propType) {
  const handleClick = (index: number) => {
    setActivesetTab(index);
  };
  return (
    <>
      <div className={style.setcontainer}>
        <header className={style.setheader}>Settings</header>
        <hr />
        <div className={style.mainsetcontainer}>
          <div className={style.setleftcontainer}>
            <div className={style.setsidebar}>
              <ul>
                <li onClick={() => handleClick(1)} className={style.setli}>
                  <div className={`${activesetTab === 1 && style.setliactive}`}>
                    {activesetTab === 1 ? (
                      <img src="/images/svg/Coach/setting/colorpersonal.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/personal.svg" />
                    )}
                    Edit personal info
                  </div>
                </li>
                <li onClick={() => handleClick(2)} className={style.setli}>
                  <div className={`${activesetTab === 2 && style.setliactive}`}>
                    {activesetTab === 2 ? (
                      <img src="/images/svg/Coach/setting/colorcertificate.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/certificate.svg" />
                    )}
                    Coach & certification
                  </div>
                </li>
                <li onClick={() => handleClick(3)} className={style.setli}>
                  <div className={`${activesetTab === 3 && style.setliactive}`}>
                    {activesetTab === 3 ? (
                      <img src="/images/svg/Coach/setting/colorexperience.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/experience.svg" />
                    )}
                    Coach experience
                  </div>
                </li>

                <li onClick={() => handleClick(4)} className={style.setli}>
                  <div className={`${activesetTab === 4 && style.setliactive}`}>
                    {activesetTab === 4 ? (
                      <img src="/images/svg/Coach/setting/coloradmin.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/admininstration.svg" />
                    )}
                    admininstration
                  </div>
                </li>
                <li onClick={() => handleClick(5)} className={style.setli}>
                  <div className={`${activesetTab === 5 && style.setliactive}`}>
                    {activesetTab === 5 ? (
                      <img src="/images/svg/Coach/setting/colorcalen.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/calendar.svg" />
                    )}
                    manage Calendar
                  </div>
                </li>
                <li onClick={() => handleClick(6)} className={style.setli}>
                  <div className={`${activesetTab === 6 && style.setliactive}`}>
                    {activesetTab === 6 ? (
                      <img src="/images/svg/Coach/setting/colorauthen.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/authenticate.svg" />
                    )}
                    Authentication
                  </div>
                </li>
                <li onClick={() => handleClick(7)} className={style.setli}>
                  <div className={`${activesetTab === 7 && style.setliactive}`}>
                    {activesetTab === 7 ? (
                      <img src="/images/svg/Coach/setting/colorpayment.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/payment.svg" />
                    )}
                    Payment Details
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className={style.setrightcontainer}>{children}</div>
        </div>
      </div>
    </>
  );
}

export default MainSettings;
