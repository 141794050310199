import React, { useState } from "react";
import Style from "../../../styles/session.module.css";
import AS1 from "./AllSessions/AS1";
import AS2 from "./AllSessions/AS2";
import AS3 from "./AllSessions/AS3";

const Session = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div className={Style.Artist}>
      {/* {Tab Container} */}

      <ul className={Style.tabCnt1}>
        <li
          className={`${Style.tab1} ${activeTab === 1 && Style.tabActive1}`}
          onClick={() => setActiveTab(1)}
        >
          All Sessions<p> </p>
        </li>
        <li
          className={`${Style.tab1} ${activeTab === 2 && Style.tabActive1}`}
          onClick={() => setActiveTab(2)}
        >
          Upcoming Sessions<p> </p>
        </li>
        <li
          className={`${Style.tab1} ${activeTab === 3 && Style.tabActive1}`}
          onClick={() => setActiveTab(3)}
        >
          Completed Sessions<p> </p>
        </li>
      </ul>
      {activeTab === 1 && <AS1 />}
      {activeTab === 2 && <AS2 />}
      {activeTab === 3 && <AS3 />}
    </div>
  );
};

export default Session;
