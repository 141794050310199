import React, { useState } from "react";
import Wrapper from "../../wrapper/Wrapper";
import Home from "../Home";
import Programs from "../Programs";
import GroupManage from "../GroupManage";
import Session from "../Session";
import Settings from "../Settings";
import SupportChat from "../SupportChat";
import UserManage from "../UserManage";
import ContactUsList from "../ContactUsList";
import FAQEdit from "../FAQEdit";
import PaymentBill from "../PaymentBill";
import Blogs from "../Blogs";
import AdminManagement from "../AdminManagement";


const AdminIndex = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <Wrapper activeTab={activeTab} setActiveTab={setActiveTab}>
      {activeTab === 1 && <Home setActiveTab={setActiveTab}/>}
      {activeTab === 2 && <UserManage />}
      {activeTab === 3 && <Session />}
      {activeTab === 4 && <Programs />}
      {activeTab === 5 && <GroupManage />}
      {activeTab === 6 && <SupportChat />}
      {activeTab === 7 && <Settings />}
      {activeTab === 8 && <ContactUsList />}
      {activeTab===9 && <FAQEdit/>}
      {activeTab===10 && <PaymentBill/>}
      {activeTab===11 && <Blogs/>}
      {activeTab===12 && <AdminManagement/>}
    </Wrapper>
  );
};

export default AdminIndex;
