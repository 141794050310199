import styles from "../../../styles/dashboardHome.module.css";
const Earning = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        {/* first */}
        <div className={styles.firstContainer}>
          <div className={styles.menuesBox}>
            <div className={styles.item}>
              {/* box 1 */}
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  LIFE TIME EARNINGS
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  src="/images/Coach/dash-user.png"
                  alt=""
                />
              </div>
              <div>
                <h5>$ 15000</h5>
              </div>
              <div className={styles.corner}>
                <img src="/images/Coach/dash-upArrow.png" alt="" />
                <span> +5.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 2 */}
              <div>
                <span
                  style={{
                    color: "#9FA4AF",
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  earnings this Month
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  src="/images/Coach/carbon_headphones.png"
                  alt=""
                />
              </div>
              <div>
                <h5>56</h5>
              </div>
              <div>
                <img src="/images/Coach/dash-upArrow.png" alt="" />
                <span> +5.34%</span>
                <p style={{ fontSize: "9px", textTransform: "capitalize" }}>
                  Since Last Month
                </p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 3 */}
              <div>
                <span
                  style={{
                    color: "#9FA4AF",
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  Upcoming Payments
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  style={{ width: "24px" }}
                  src="/images/Coach/hour-glass.png"
                  alt=""
                />
              </div>
              <div>
                <h5>1299</h5>
              </div>
              <div>
                <img src="/images/Coach/down-arrow-red.png" alt="" />
                <span> -6.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 4 */}
              <div>
                <span
                  style={{
                    color: "#9FA4AF",
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  Available Balance
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  style={{ height: "33px" }}
                  src="/images/Coach/money-bag.png"
                  alt=""
                />
              </div>
              <div>
                <h5>$1250</h5>
              </div>
              <div className={styles.corner}>
                <img src="/images/Coach/down-arrow-red.png" alt="" />
                <span> -6.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
          </div>
          <div className={styles.ChartGraph}>
            <div className={styles.headLine}>
              <h5>Total Sessions</h5>{" "}
            </div>
            <div>
              <div className={styles.selectBar}>
                <select name="year" id="year">
                  <option value="year">This Year</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* second */}
        <div className={styles.middleContainer}>
          <div className={styles.headerLine}>
            <div>
              <h3 style={{ fontSize: "24px" }}>
                {" "}
                <img src="/images/Coach/rectangle-shape.png" alt="" />
                Payment Details
              </h3>
            </div>
            <div>
              <h4 style={{ color: "#1C74BC", fontSize: "16px" }}>View All</h4>
            </div>
          </div>
          <div className={styles.tables}>
            <table>
              <thead>
                <tr>
                  <th>CLIENT NAME</th>
                  <th>TOTAL SESSION TAKEN</th>
                  <th>AMOUNT</th>
                  <th>PAYMENT DATE</th>
                  <th>STATUS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.img_text}>
                    <div>
                      <img src="/images/Coach/pic-placeholder.png" alt="" />
                    </div>
                    <div className={styles.names}>Sachin das</div>
                  </td>

                  <td>8 Session</td>
                  <td>$1,200</td>
                  <td>May 18, 2022 10:40 AM</td>
                  <td>
                    <div
                      style={{
                        textAlign: "center",
                        marginRight: "18px",
                        color: "#7AA146",
                      }}
                    >
                      <img
                        src="/images/svg/Coach/done.svg"
                        alt=""
                        style={{ marginRight: "6px" }}
                      />
                      Payment Received
                    </div>
                  </td>
                  <td>Invoice</td>
                </tr>
                <tr>
                  <td className={styles.img_text}>
                    <div>
                      <img src="/images/Coach/pic-placeholder2.png" alt="" />
                    </div>
                    <div className={styles.names}>neha r</div>
                  </td>
                  <td>8 Session</td>
                  <td>$1,200</td>
                  <td>May 18, 2022 10:40 AM</td>
                  <td>
                    <div
                      style={{
                        textAlign: "center",
                        marginRight: "18px",
                        color: "#7AA146",
                      }}
                    >
                      <img
                        src="/images/svg/Coach/done.svg"
                        alt=""
                        style={{ marginRight: "6px" }}
                      />
                      Payment Received
                    </div>
                  </td>
                  <td>Invoice</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* third */}
      </div>
    </>
  );
};

export default Earning;
