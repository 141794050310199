import { green } from "@mui/material/colors";
import styles from "./session.module.css";
import { colors } from "@mui/material";
import { Color } from "antd/es/color-picker";
import { Handshake } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const AsTab2 = ({ setActiveTab }: propType) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.container1}>
          <input
            className={styles.container2}
            type="text"
            placeholder="Which client are you looking for"
            style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
          />
          <div>
            <table className={styles.sessiontable}>
              <tr>
                <th> CLIENT NAME</th>
                <th> TOTAL SESSION TAKEN</th>
                <th> LATE SESSION</th>
                <th> ENGAGEMENT LEVEL</th>
                <th> COMMENTS</th>
                <th> STATUS</th>
            
              </tr>
              <tr>
              <td className={styles.showinfoConte}>
                <img src="/images/Coach/pic1.png" alt="" className={styles.showCinfoImg}/>
                <div className={styles.showCInfo}>
                  <span className={styles.showCinofP}>John ABCD</span>
                  <span className={styles.showCinofProg}> John ABCD</span>

                </div>
                </td>
                <td>7 Session</td>
                <td>May 18, 2022 10:40 AM</td>
                <td>Engaged</td>
                <td>View Comment</td>
                <td><CheckCircleIcon/>Completed</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default AsTab2;
