import style from "./faq.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import AddIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";

const Faq = () => {
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({
    panel1: true,
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
  });

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded({ ...expanded, [panel]: isExpanded });
    };
  return (
    <>
      <div className={style.container}>
        <div className={style.accordioncontainer}>
          <div className={style.datacontent}>
            <Accordion
              expanded={expanded["panel1"]}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={expanded["panel1"] ? <RemoveIcon /> : <AddIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={style.accordion}
              >
                What is Lorem Ipsum?
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={style.datacontent}>
            <Accordion
              expanded={expanded["panel2"]}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={expanded["panel2"] ? <RemoveIcon /> : <AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                className={style.accordion}
              >
                Why do we use it?
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={style.datacontent}>
            <Accordion
              expanded={expanded["panel3"]}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={expanded["panel3"] ? <RemoveIcon /> : <AddIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={style.accordion}
              >
                Where does it come from?
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={style.datacontent}>
            <Accordion
              expanded={expanded["panel4"]}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={expanded["panel4"] ? <RemoveIcon /> : <AddIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={style.accordion}
              >
                Where can I get some?
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={style.datacontent}>
            <Accordion
              expanded={expanded["panel5"]}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={expanded["panel5"] ? <RemoveIcon /> : <AddIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={style.accordion}
              >
                Lorem Ipsum is simply dummy text
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={style.datacontent}>
            <Accordion
              expanded={expanded["panel6"]}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={expanded["panel6"] ? <RemoveIcon /> : <AddIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={style.accordion}
              >
                Lorem Ipsum is simply dummy text
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
