import { useEffect, useState } from "react";
import styles from "../../../Dashboard/Coach/all-session/session.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CachedIcon from '@mui/icons-material/Cached';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { fetchCoach } from "../../../../Api";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  setCoachUid: (val: (v: any) => any) => void
};
const UM1 = ({ setActiveTab,setCoachUid }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  console.log("profile", user?.profileSetup);
  const cookie: any = user?.token;
  const [coachData, setCoachData] = useState<any[]>([]);
  useEffect(() => {
    fetchCoach(cookie,1)
      .then((result: any) => {
        console.log("Coach data:", result);
        setCoachData(result.data.history);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });
  }, [cookie]);
const handleUid=(uid:any)=>{
  setCoachUid(uid)
  setActiveTab(()=>3);

}

  return (
    <div>
      <div className={styles.container}>
        <input
          className={styles.container2}
          type="text"
          placeholder="Search user name here"
          style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
        />
        <div>
          <table className={styles.sessiontable}>
            <tr>
              <th>USER NAME</th> <th>EMAIL</th>
              <th>STATUS</th>
              <th>SESSION</th>
              <th>SESSION COMPLETED</th>
              <th></th>
            </tr>

            {coachData.length > 0 ? (
              coachData.map((coach: any, index: number) => (
                <>
                  <tr className={styles.sessiontable}>
                    <td>
                      {coach.profileImg?( <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                        className={styles.coachImg}
                      />):(<img src="/images/svg/Coach/setting/personal.svg" className={styles.coachImg2} />)}
                     
                      {coach.name}
                    </td>
                    <td>{coach.email}</td>

                    <td className={styles.alignCenter}>
                      {/* {coach.account_status} */}
                     {coach.account_status===0?(<p className={styles.AccStatus0}><HourglassTopIcon/>Unverified</p>):coach.account_status===1?
                     (<p className={styles.AccStatus1}><CachedIcon/>Pending</p>):coach.account_status===2?(<p className={styles.AccStatus2}><ThumbUpAltIcon/>Active</p>):coach.account_status===3?
                     (<p className={styles.AccStatus3}><BlockIcon/>Blocked</p>)
                     :coach.account_status===4?(<p className={styles.AccStatus4}><DeleteIcon/>Deleted</p>):(<p className={styles.AccStatus4}><PersonOffIcon/>Not Found!</p>)}
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <img src="/images/svg/Admin/viewIcon.svg" onClick={()=>handleUid(coach._id)}/>
                      <img src="/images/svg/Admin/lockIcon.svg" />
                      <img src="/images/svg/Admin/deletIcon.svg" />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <p>No coach data available</p>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default UM1;
