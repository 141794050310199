import AuthWrapper from "../layouts/authWrapper";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
  
// payload
const ZoomAPI = () => {

  return (
    <>
      <AuthWrapper>
       <h1>Meeting will be here</h1>
      </AuthWrapper>
    </>
  );
};

export default ZoomAPI;
