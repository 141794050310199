import styles from "./session.module.css";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const AsTab1 = ({ setActiveTab }: propType) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.container1}>
          <input
            className={styles.container2}
            type="text"
            placeholder="Which client are you looking for"
            style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
          />
          <div>
            <table className={styles.sessiontable}>
              <tr>
                <th> CLIENT NAME</th>
                <th> TOTAL SESSIONS TAKEN</th>
               
                <th>DATE </th>
                <th> TIME SLOT</th>
                <th></th>
              
              </tr>
              <tr>
                <td className={styles.showinfoConte}>
                <img src="/images/Coach/pic1.png" alt="" className={styles.showCinfoImg}/>
                <div className={styles.showCInfo}>
                  <span className={styles.showCinofP}>John ABCD</span>
                  <span className={styles.showCinofProg}> John ABCD</span>

                </div>
                </td>
                <td>10 Session</td>
                <td>Date</td>
                <td>Time Slot</td>
                <td>
                  <button className={styles.StartSessBtn}>Start Session</button>
                  <button className={styles.SendMsg}>Send Message</button>
                </td>
              </tr>
            
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default AsTab1;
