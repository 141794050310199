import style from "./profile.module.css";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { useState } from "react";
import UserProfile from "./ProfileData/UserProfile";
import Webinars from "./ProfileData/Webinars";
import MyReview from "./ProfileData/MyReview";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
type propType = {
    setActiveTab: (val: (v: number) => number) => void;
  };


const ProfileHome=({setActiveTab }: propType)=>{
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const [acctive,setAcctive]=useState(1);
    return(
             <div className={style.ProfileContent}>
                
            <div className={style.ProfileHead}>
                
                <h5> My Profile</h5>
                <button onClick={()=>{setActiveTab(()=>8)}}>  <img src="/images/svg/Coachee/whiteMyRepo.svg" /> Take Free Assessment</button>
                </div> 
                <div className={style.ProfileDataContent}>
                <div className={style.ProfileData}>
                    <div className={style.ProfileDataleft}>
                    <div className={style.profile} >
                {/* profile css  */}
                <img src="./images/Coach/shape-2x.png" className={style.profileImg} />
               
                    </div>
                    <div className={style.ProfileName}>
                  <h5>{user?.name}</h5>
                  {/* <p><EmailIcon/>{user?.email}</p>   */}
                  <p><LocalPhoneIcon/></p>
                </div>
                    </div>
                    <div className={style.ProfileDataRight}>
                    <div className={style.ProfileDataRightContent}>
                        <ul>
                            <li   onClick={() => setAcctive(1)} className={` ${acctive === 1 && style.Activate1}`}>
                                <img src="/images/svg/Coachee/userProfile.svg"/>Profile</li>
                             <li  onClick={() => setAcctive(3)} className={` ${acctive === 3 && style.Activate1}`}><img src="/images/svg/Coachee/webinar.svg"/>Webinars</li>
                            <li  onClick={() => setAcctive(4)} className={` ${acctive === 4 && style.Activate1}`}><img src="/images/svg/Coachee/myReview.svg"/>My Reviews</li>
                        </ul>
                        {acctive === 1 && <UserProfile/>}
                        {acctive===3 && <Webinars/>}
                        {acctive===4 && <MyReview/>}
                        </div>
</div>

                </div>
            
                </div>    
               </div>
     
    )
}
export default ProfileHome;