import style from "./profiledata.module.css";
import styles from "../../CSS/dashboardHome.module.css";
const Webinars=()=>{
    return(
        <>
        <div className={style.UserProfile}>
            <h6>All Scheduled Webinars</h6>
            <div className={styles.UpcomingContainer}>
         
          <div className={styles.Recommond}>
          <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
              <div className={styles.coachingTxt}>
                <p>Coach Name</p>
                <div className={styles.category}>
                  <span>Category 1</span>
                  <span>Category 2</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>abc@gamil.com</span>
                </div>
              </div>
              </div>
              {/* another */}
              
              
              <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
             <div className={styles.coachingTxt}>
                <p>Coach Name</p>
                <div className={styles.category}>
                  <span>Category 1</span>
                  <span>Category 2</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>abc@gamil.com</span>
                </div>
              </div>
              </div>
              {/*  */}
             
              {/*  */}
 
          </div>
         </div>
        </div>
        </>
    )

}
export default Webinars;