import TextArea from "antd/es/input/TextArea";
import styles from "./setup.module.css";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

import { MenuItem, Select, TextField } from "@mui/material";
import { setProfileSetup } from "../../redux/userSlice";
import { bankDetails } from "../../Api";
import { notifySuccess } from "../../Notify";
import { useStripe } from "@stripe/react-stripe-js";
import Stripe from "stripe";
import { loadStripe } from "@stripe/stripe-js";
const stripe: any = new Stripe('pk_test_51PfLhcEA1Dv5o2gG8FdEhF8ozkCwBdYy7YnqgpMNAY2OUwdwzy6CBEDsmCiIjJi2mnkWTt0qKOLDgSOIWQoTELpC008qsIXwPp');

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const CpTab5 = ({ setActiveTab }: propType) => {

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  console.log("cpTab3 ", user);
  const [token, setToken] = useState('')
  const cookie: any = user?.token;

  const ssNumber = "000000000";
const hourlyRates="20";

  // bankaccount

  const accountData = ({
    bankName: "some",
    accType: "individual",
    accNo: "000123456789",
    routingNo: "110000000"
  })


  const handleAccVal = (event: any) => {
    alert(event.target.value)
  }

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const tokenn = await stripe.tokens.create({
          bank_account: {
            country: 'US',
            currency: 'usd',
            account_holder_name: user?.name,
            account_holder_type: 'individual',
            routing_number: '110000000',
            account_number: '000123456789',
          },
        });

        console.log("tokenn Data", tokenn);

        setToken(tokenn.id);
      } catch (error) {
        console.error("Error creating token", error);
      }
    };

    fetchToken();
  }, []);
  const bankaccount = JSON.stringify(accountData)
  const handleSubmit = (e: any) => {
    e.preventDefault();

    console.log("JSON DATA", accountData);

    
console.log("details of coach bank",bankaccount,ssNumber,token,cookie)
    bankDetails(bankaccount, ssNumber,token,hourlyRates , cookie)
      .then((result: any) => {
        console.log(result.data);
        notifySuccess(result.data.message)
        setActiveTab((prev) => prev + 1);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.inputContent}>
          <h3>Coaching Related Information</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputContent}>
            <div className={styles.LeftInputField}>

              <TextField
                type="text"
                id="outlined-basic"
                label="Enter Your 1 Hr rate"
                variant="outlined"
                name="phoneNo"

                //   value={newUser.phoneNo}
                //   onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
            </div>
            <div className={styles.RightInputField}>
              <TextField
                type="text"
                id="outlined-basic"
                label="SSN Number of Coach"
                variant="outlined"
                name="phoneNo"
                //   value={newUser.phoneNo}
                //   onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
            </div>
          </div>



          <br />
          <br />
          <h3>Bank Details </h3>

          <div className={styles.inputContent}>
            <div className={styles.LeftInputField}>
              <TextField
                type="text"
                id="outlined-basic"
                label="Bank Name"
                variant="outlined"
                name="phoneNo"
                //   value={newUser.phoneNo}
                //   onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="Account Number"
                variant="outlined"
                name="phoneNo"
                //   value={newUser.phoneNo}
                //   onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
            </div>

            <div className={styles.RightInputField}>
              {/* <TextField
              type="text"
              id="outlined-basic"
              label="Account Type"
              variant="outlined"
              name="phoneNo"
            //   value={newUser.phoneNo}
            //   onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            /> */}
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                // value={value}

                onChange={handleAccVal}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "10px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
                className={styles.Inputfield}
                label="Select Card"
              >
                <MenuItem value="individual" className={styles.MenuItems}>
                  Individual Account
                </MenuItem>
                <MenuItem value="company" className={styles.MenuItems}>
                  Company Account
                </MenuItem>
              </Select>
              <TextField
                type="text"
                id="outlined-basic"
                label="Routing Number"
                variant="outlined"
                name="phoneNo"
                //   value={newUser.phoneNo}
                //   onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
            </div>
          </div>

          <br /><br />
          <div className={styles.textcenter1}>
            <button className={styles.button4} type="submit">
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CpTab5;
