import styles from "./wrapper/wrapper.module.css";

const PaymentBill=()=>{
return(<>
<div className={styles.mainContiner}>
<table>
    <br/>
    <tr>
        <th>TRANSACTION ID</th>
        <th>USER NAME</th>
        <th>COACH NAME</th>
        <th>SESSION TYPE</th>
        <th>DATE AND TIME</th>
        <th>SESSION STATUS</th>
        <th>COACH EARNING</th>
        <th>PAYMENT STATUS</th>
    </tr>
</table>
</div>
</>)
}
export default PaymentBill;