import style from "./profiledata.module.css"
const UserProfile=()=>{
return(
    <>
    <div className={style.UserProfile}>
        <h6>Personal Info</h6>
        <div className={style.UserDataheadContent}>
        <div className={style.UserDatahead}>
        <p>First Name</p>
        <p>Last Name</p>
      
        <p>Mono</p>
        <p>Address</p>
        <p>City</p>
        </div>
        <div className={style.UserData}>
        <span>John</span>
        <span>Doe</span>
        <span>796-451-1071</span>
        <span>41, willson street, near joren, New york city, ny.</span>
        <span>New Yark</span>
        </div>
        </div>
    </div>
    </>
)
}

export default UserProfile;