import { useState } from "react";
import styles from "./reset.module.css";
import { ResetPass } from "../../Api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type propType = {
  setResetSuccess: (val: boolean) => void;
};

const ResetPassword = ({ setResetSuccess }: propType) => {
  const user: any = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();
  const [newPassword, setnewPassword] = useState("");
  const [cPassword, setcPassword] = useState("");
  const handleSubmit = () => {
    ResetPass(newPassword, cPassword, cookie).then((result: any) => {});
    setResetSuccess(true);
  };
  const cookie: any = user?.token;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={styles.InputText1}>
          <input
            type="password"
            name="newPassword"
            value={newPassword}
            onChange={(e: any) => setnewPassword(e.target.value)}
            placeholder="New Password"
          />
        </div>
        <div className={styles.InputText1}>
          <input
            type="password"
            name="cPassword"
            value={cPassword}
            onChange={(e: any) => setcPassword(e.target.value)}
            placeholder="Confirm Password"
          />
        </div>
        <div>
          <button className={styles.btn} type="submit">
            Reset Password
          </button>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
