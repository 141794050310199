import { useState } from "react";
import AsTab1 from "./all-session/AS-Tab1";
import AsTab2 from "./all-session/AS-Tab2";
import AsTab3 from "./all-session/AS-Tab3";
import AsTab4 from "./all-session/AS-Tab4";
import Style from "../../../../src/styles/session.module.css";


const Session = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      <div className={Style.Artist}>
        {/* {Tab Container} */}
        {activeTab < 5 && (
          <ul className={Style.tabCnt1}>
            <li
              className={`${Style.tab1} ${activeTab === 1 && Style.tabActive1}`}
              onClick={() => setActiveTab(1)}
            >
              Upcoming Sessions
            </li>
            <li
              className={`${Style.tab1} ${activeTab === 2 && Style.tabActive1}`}
              onClick={() => setActiveTab(2)}
            >
              Completed Sessions
            </li>
            <li
              className={`${Style.tab1} ${activeTab === 4 && Style.tabActive1}`}
              onClick={() => setActiveTab(4)}
            >
              Manage Requests
            </li>
           
          </ul>
        )}
        {activeTab === 1 && <AsTab1 setActiveTab={setActiveTab} />}
        {activeTab === 2 && <AsTab2 setActiveTab={setActiveTab} />}
        {activeTab === 3 && <AsTab3 setActiveTab={setActiveTab} />}
        {activeTab === 4 && <AsTab4 setActiveTab={setActiveTab} />}
      </div>
    </>
  );
};

export default Session;
