import SetTab1 from "./Settings/Set-Tab1";
import SetTab2 from "./Settings/Set-Tap2";
import SetTab3 from "./Settings/Set-Tab3";
import SetTab4 from "./Settings/Set-Tab4";
import SetTab5 from "./Settings/Set-Tab5";
import SetTab6 from "./Settings/Set-Tab6";
import SetTab7 from "./Settings/Set-Tab7";

import { useState } from "react";
import MainSettings from "./Settings/MainSettings";
import DisplayEvent from "./Settings/SetTab5/DisplayEvent";
import SetActivites from "./Settings/SetTab5/SetActivites";

const Settings = () => {
  const [activesetTab, setActivesetTab] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  return (
    <MainSettings activesetTab={activesetTab} setActivesetTab={setActivesetTab}>
      {activesetTab === 1 && <SetTab1 />}
      {activesetTab === 2 && <SetTab2 />}
      {activesetTab === 3 && <SetTab3 />}
      {activesetTab === 4 && <SetTab4 />}
      {activesetTab === 5 && (
        <SetTab5 activeTab={activeTab} setActiveTab={setActiveTab}>
          {activeTab === 1 && <DisplayEvent />}
          {activeTab === 2 && <SetActivites />}
        </SetTab5>
      )}
      {activesetTab === 6 && <SetTab6 />}
      {activesetTab === 7 && <SetTab7 />}
    </MainSettings>
  );
};

export default Settings;
