import { useEffect, useState } from "react";
import { fetchCoachDataForCoachee, fetchCoacheeGroup } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import style from "./CSS/group.module.css"
import SearchIcon from '@mui/icons-material/Search';
import ShowGroups from "./Program/ShowGroups";
const MyGroups=()=>{
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const[group,setGroup]=useState<any[]>([])
    const[groupName,setgroupName]=useState('')
    const[coachId,setCoachId]=useState('')
    const[userState,setUserState]=useState(false)
    const[coachData,setCoachData]=useState<any[]>([])
    const[activeTab,setActiveTab]=useState(0)

    useEffect(()=>{
        fetchCoacheeGroup(cookie)
        .then((res:any)=>{
            console.log("this is Coachee Group",res)
            setGroup(res.data.history)
            
            setUserState(true)
        })
    },[cookie])
   
const GroupIcon=(e:any,groupTopic:any)=>{
    e.prevenDefault()
    
    const groupNm=groupTopic.slice(" ")

    setgroupName(groupNm)
    
}
const handleId=(e:any,gid:any)=>{
    e.preventDefault();
    setActiveTab(1)
ShowGroups(gid)
setCoachId(gid)
}

    return(<>
    {activeTab===0 && ( <div className={style.groupContent}>
        <div className={style.groupInputContent}>
   <SearchIcon/> <input type="text" placeholder="Which Client are you looking for" className={style.groupInput}/>
    </div>
    <div className={style.showGroups}>
        
        <table>
            <tr>
            <th>GROUP TOPIC</th>
            <th>ASSIGNED COACH</th>
            <th>TOTAL SESSION</th>
            <th>SESSION COMPLETED</th>
            <th>DATE</th>
            <th></th>
            </tr>
            {/*  */}
           
                {group.length>0?(group.map((gp:any)=>(
                
                    <tr>
                        <td className={style.groupTd} ><p className={style.groupTdpara}>{gp.groupTopic.slice(0,2)}</p><p>{gp.groupTopic}</p></td>
                    <td>{gp.coachIds}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>{gp.createdAt.slice(0,10)}</td>
                    <td><img src="/images/svg/Admin/viewIcon.svg" onClick={(e:any)=>handleId(e,gp._id)} /></td>
                  
                    </tr>
                  
                ))):(<p>No group Found!</p>)}
             
        </table>

    </div>
    </div>)}
    {activeTab === 1 && (<ShowGroups coachId={coachId}/>)}
   
    </>)
}
export default MyGroups;