import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { fetchCard, fetchCoachByCoachee, fetchCoacheeProgramByID } from "../../../../Api";
import style from "./showPro.module.css";
import Modal from "@mui/material/Modal";
import EmailIcon from '@mui/icons-material/Email';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import styles from "../CSS/dashboardHome.module.css";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ShowRecordProgram from "./ShowRecordProgram";
type propType = {
    progId: any
  }
const LiveProgram=({ progId }: propType)=>{
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [proImg, setProgImg] = useState("")
    const [pdate, setPdate] = useState("")
    const [desc, setDesc] = useState("");
    const [pname, setPname] = useState("")
    const [progType, setProgType] = useState()
    const [overallCost, setoverallCost] = useState("")
    const[cardId,setCardId]=useState("")
    const [cards,setCards]=useState<any[]>([]);
  
    const [coachData, setCoachData] = useState<any[]>([]);
    useEffect(() => {
      fetchCoacheeProgramByID(cookie, progId)
        .then((res: any) => {
          setProgImg(res.data.programImg)
          setPname(res.data.name);
          setPdate(res.data.createdAt)
          setoverallCost(res.data.overallCost)
          setDesc(res.data.description)
          setCoachData(res.data.coacheIds)
          setProgType(res.data.program_type)
          
  
        })
    }, [cookie, progId])
    useEffect(()=>{
      fetchCard(cookie)
      .then((result:any)=>{
        setCards(result.data)
        console.log("Card details",result)
        
      })
      .catch((error:any)=>{
        console.log("from Card",error)
      })
    },[cookie])
  
    const handleCardVal = (event:any) => {
  
      setCardId(event.target.value)
      }
    return (<>
      <div className={style.Allcontent}>
    <div className={style.ProgContent}>
      <div className={style.ProgContentLeft}>
        <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${proImg}`} className={style.ProgImg} />
        <div>
          <h6>{pname}</h6>
          <p>{pdate.slice(0, 10)}</p>
          <p>$  {overallCost}</p>
          <p></p>
        </div>
      </div>
      <div className={style.ProgContentRight}>
        <button onClick={handleOpen}>
          Enroll Now
        </button>
        <div className={styles.upgradeButton}>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={styles.BoxStyle}>
              <span onClick={handleClose} className={styles.HandleClose}>
                <CloseTwoToneIcon className={styles.HandleCloseIcon} />
              </span>
              <Typography id="modal-modal-title" variant="h5" component="h2">
              {progType === 2 && <>Make Payment</>}
              {progType === 1 && <>Enroll Live Class</>}
              </Typography>
              <Typography
                id="modal-modal-description"
                className={styles.typoDesc}
              >
                {progType === 1 && (<>
                  
                  <h6>Select Coach</h6>
                  <div className={style.coachDataModal}>
                  {coachData.length > 0 ? (coachData.map((coach:any)=>(
                    <>
                  <div className={style.ModalContent}>
                   <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                    className={style.ModalcoachingImg}
                  />
                  <h6>{coach.name}</h6>
                  <p><EmailIcon/> abc@gmail.abc</p>
                 

                  </div>
                    </>
                  ))):(<></>)}
                  
                   </div>
                   <h6> Select Availiability Slot</h6>
                   <div className={style.ProgContentRight}>
                   <button>Next</button></div>
                 
                </>)}
                {progType === 2 && (<>
                  <div className={style.enrolledRecord}>
                  <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${proImg}`} className={style.enrolledRecordImg} />
                    <div className={style.enrolledRecordData}>
                      <h6>{pname}</h6>
                      <p>{pdate.slice(0, 10)}</p>
                      <p>$  {overallCost}</p>
                    </div>
                    
                  </div>
                  <div className={style.enrolledRecordPrice}>
                   
                    <h4>Total Payment </h4>  
                      <h6> $ {overallCost}</h6>
                    </div>
                    <Select
    onChange={handleCardVal}
    className={styles.cardDesignSelect}
    labelId="demo-simple-select-standard-label"
    id="demo-simple-select-standard"
    label="Select Card"
  >
    {cards && cards.length > 0 ? (
      cards.map(cinfo => (
        <MenuItem key={cinfo.id} value={cinfo.id} className={styles.MenuItems}>
          <div className={styles.MenuItems}>
            <p><CreditCardIcon /> John Doe</p>
            <p className={styles.MenuItemsIC}>
              <FormatListNumberedIcon className={styles.MenuItemsIC} />
              {' '}.... .... .... {cinfo.last4}
            </p>
          
          </div>
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled>
        <p className={styles.dataColors}>Please Add Cards.. Go To Settings!</p>
      </MenuItem>
    )}
  </Select>
                    <button className={style.enrolledRecordBtn}>Pay Now</button>
                </>)}


              </Typography>
            </Box>
          </Modal>
        </div>
      </div>

    </div>
    <hr />
    <div className={style.ProgDescr}>
      <div className={style.ProgramDesc}>
        <h6> Description </h6>
        <p>{desc}</p>
        <p>Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown printer took a
          galley of type and scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
          Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker


          been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more .
          <br />
          <br />
        </p>
      </div>
      <div className={style.ProgEnroll}>
        <div className={styles.DashForRecom}>
          <div className={styles.Recommond}>
            {coachData.length > 0 ? (coachData.map((cdata: any) => (
              <>
                <div className={styles.coaching}>
                  <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.profileImg}`}
                    className={styles.coachingImg}
                  />
                  <div className={styles.coachingTxt} >
                    <p>{cdata.name}</p>
                    <div className={styles.Description}>

                      <p> {cdata.email}</p>
                    </div>

                  </div>
                </div>
              </>
            ))) : (<p>No coach Available</p>)}
          </div>
        </div>
      </div>
    </div>
  </div>
    </>)
}
export default LiveProgram;