import { Button, Box, TextField} from "@mui/material";
import style from "./wrapper/landing.module.css";
import TextArea from "antd/es/input/TextArea";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { ContactFormData } from "../Api";

import { useState } from "react";
import { notifySuccess } from "../Notify";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};

const ContactUs = ({ setActiveTab }: propType) => {
 const [data,setData]:any=useState({
  firstName:"",
  lastName:"",
  email:"",
  phoneno:"",
  message:""

 })

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(data.firstName)
   
    const formData:any= new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName",  data.lastName);
    formData.append("email",  data.email);
    formData.append("phoneno",  data.phoneno);
    formData.append("message",  data.message);
    ContactFormData(data)
      .then((result: any) => {
        console.log(result);
        notifySuccess("Response Recorded!")
        setData({
          firstName: "",
          lastName: "",
          email: "",
          phoneno: "",
          message: "",
        });
      })
      .catch((error) => {
        console.log(error);
      })
  }
  
  return (
    <>
      <div className={style.HomeContainer}>
        <div className={style.headerContainer}>
          <div className={style.BlogHeadr}>
            <div className={style.ServiceLeft}>
              <span className={style.ServiceSpan}>Contact Us</span>
              <h2>
                Fill the form Detail to <br />
                Contact us
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and type
                setting industry. has been the industry standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type.
              </p>
              <div className={style.ContactUsForm}>
                <form onSubmit={handleSubmit}>
                  <h4>Contact Us</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and type
                    setting industry. has been the industry standard dummy text
                    ever since type.
                  </p>
                 
                  <div className={style.ContactUsName}>
                  
                    <TextField
                       id="outlined-basic"
                      type="text"
                       variant="outlined"
                      label="First Name"
                      name="firstName"
                       value={data.firstName}
                       onChange={(e: any) => setData({...data,firstName:e.target.value})}
                       sx={{
                        my: 1,
                        mr:2,
                        borderRadius: "20px",
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                      
                      }}
                      
                      className={style.formcontent}
                    />
                    <TextField
                      id="full-width-text-field"
                      type="text"
                      label="Last Name"
                      name="lastName"
                      value={data.lastName}
                      onChange={(e: any) => setData({...data,lastName:e.target.value})}
                     
                      sx={{
                        mr: 1,
                        my: 1,
                        borderRadius: "20px",
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                      }}
                      variant="outlined"
                      className={style.formcontent}
                    />
                  </div>
                  <TextField
                    id="full-width-text-field"
                    type="text"
                    label="Email Address"
                    name="email"
                    value={data.email}
                       onChange={(e: any) => setData({...data,email:e.target.value})}
                    sx={{
                      mr: 1,
                      my: 1,
                      borderRadius: "20px",
                      "& .MuiInputBase-input": {
                        width: "100%",
                      },
                    }}
                    variant="outlined"
                    className={style.formcontent}
                  />
                  <TextField
                    id="full-width-text-field"
                    type="text"
                    label="Phone Number"
                    sx={{ m: 0, width: "100%" }}
                    variant="outlined"
                    name="phoneno"
                    value={data.phoneno}
                    onChange={(e: any) => setData({...data,phoneno:e.target.value})}
                    className={style.formcontent}
                  />
                  <TextArea
                    id="full-width-text-field"
                    placeholder="Enter Message"
                    name="message"
                    className={style.formcontentArea}
                    value={data.message}
                    onChange={(e: any) => setData({...data,message:e.target.value})}
                    rows={5}
                    
                  ></TextArea>
                  
                  <button type="submit" className={style.formcontentButton}>Submit</button>
               
                </form>
              </div>
            </div>
            <div className={style.BlogHeadrRight}>
              <img
                src="/images/svg/Admin/ContactUs.svg"
                className={style.ContactHeadImg}
              />{" "}
              <div className={style.ContactInform}>
                <div className={style.ContactEmailInfo}>
                  <EmailIcon />
                  <p>
                    <b>abcd123@gmail.com</b>
                  </p>
                </div>
                <div className={style.ContactEmailInfo}>
                  <LocalPhoneIcon />
                  <p>
                    <b>(+41) 7854 5485 652</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
