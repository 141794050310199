import { useSelector } from "react-redux";
import styles from "./group.module.css";
import { RootState } from "../../../../redux/store";
import { useEffect, useState } from "react";
import { fetchCoachGroup } from "../../../../Api";
import { notifyError } from "../../../../Notify";
import Style from "../../../../../src/styles/group.module.css";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  setGroupId:any
};
const GeTab1 = ({ setActiveTab,setGroupId }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [group,setGroup]=useState<any[]>([]);
  
  useEffect(()=>{
    fetchCoachGroup(cookie)
    .then((result: any) => {
      setGroup(result.data)
    
    })
    .catch((error: any) => {
      console.error("Error fetching coach data:", error);
      notifyError(error);
    });
}, [cookie]);
const handleId=(groupId:any)=>{
  setGroupId(groupId)
  setActiveTab(()=>2)
}
  return (
    <div className={styles.maincontainer}>
      
 
      <div className={styles.search}>
        <input
          className={styles.box1}
          placeholder="which client are you looking for"
          style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
        />
      </div>
      <div className={Style.maindiv} style={{ overflowX: "auto" }}>
        <table className={Style.curvedbordertable}>
          <tr>
            <th>GROUP TOPIC </th>
            <th>TOTAL SESSION</th>
            <th>SESSION COMPLETED</th>
            <th>DATE </th>
            <th>TOTAL USERS</th>
            <th></th>
          </tr>
          
          <tr className={Style.tableTr}>
     {group.length > 0 ? (
                group.map((gp: any, index: number) => (<>
           
              <td className={Style.groupInfo}><span className={Style.groupIcon}>{gp.groupTopic.slice(0,2).toString().toUpperCase()}</span>{gp.groupTopic}</td>
              <td>Total Session</td>
              <td>Completed Session</td>
              <td>{gp.createdAt.slice(0,10)}</td>
              <td>{gp.coacheeIds.length}</td>
              <td><img src="/images/svg/Admin/viewIcon.svg" onClick={()=>handleId(gp._id)}/></td>
           
     </>
        ))
      ) : (
        <p>No coach data available</p>
      )}
       </tr>
  </table>
   </div>
  </div>
      

    
  );
};

export default GeTab1;
