import styles from "./group.module.css";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const GeTab3 = ({ setActiveTab }: propType) => {
  return (
    <>
      <div className={styles.maincontainer}>
        <div className={styles.search}>
          <input
            className={styles.box1}
            placeholder="which client are you looking for"
            style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
          />
        </div>
        <div>
          <table className={styles.curvedbordertable}>
            <tr>
              <th>GROUP NAME</th>
              <th>SESSION TYPE</th>
              <th>DURATION</th>
              <th>DATE AND TIME</th>
              <th>ENGAGEMENT LEVEL</th>
              <th>COMMENTS</th>
              <th>STATUS</th>
              <th></th>
            </tr>
            <tr>
              <td className={styles.tab3}>
                <div>
                  <img
                    src="/images/svg/Coach/placeholder.svg"
                    style={{ height: "30px" }}
                    alt=""
                  />
                </div>
                <div className={styles.tabb3}>Group Name</div>
              </td>
              <td>Type Here</td>
              <td>30 min</td>
              <td>May 18,2022 10:40 AM</td>
              <td>Engaged</td>
              <td className={styles.comment}>View comment</td>
              <td style={{ color: " #7AA146" }}>
                <img src="/images/done.png" alt="" style={{ margin: "3px" }} />{" "}
                Completed
              </td>

              <td className={styles.td3}>View More</td>
            </tr>
            <tr>
              <td className={styles.tab3}>
                <div>
                  <img
                    src="/images/svg/Coach/placeholder.svg"
                    style={{ height: "30px" }}
                    alt=""
                  />
                </div>
                <div className={styles.tabb3}>Group Name</div>
              </td>
              <td>Type Here</td>
              <td>30 min</td>
              <td>May 18,2022 10:40 AM</td>
              <td>Engaged</td>
              <td className={styles.com}>View comment</td>
              <td style={{ color: " #7AA146" }}>
                <img src="/images/done.png" alt="" style={{ margin: "3px" }} />{" "}
                Completed
              </td>
              <td className={styles.td3}>View More</td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

export default GeTab3;
