import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import style from "./blogs.module.css";
import {  TextField} from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import styles from "../wrapper/wrapper.module.css";
import { CreateNewBlog } from '../../../../Api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { notifySuccess } from '../../../../Notify';
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  setUpdata:any;
};
interface NewUser {
  pImg: File | null;
}
const CreateBlog=({setActiveTab,setUpdata}:propType)=>{
    const [editorHtml, setEditorHtml] = useState('');
    const [previewImage, setPreviewImage] = useState("");
    const[title,setBhead]=useState('')
    const[description,setBdesc]=useState('')
    const[content,setbBody]=useState('')
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const [newUser, setNewUser] = useState<NewUser>({
      pImg: null,  
    });
    const handleChange = (html:any) => {
      setEditorHtml(html);
      setbBody(html)
    };
    const handleProfileClick = () => {
      const fileInput = document.getElementById("fileInput");
      if (fileInput) {
        fileInput.click();
      }
    }; 
    const handleProfile = (e: any) => {
      const file = e.target.files[0];
      setNewUser({ ...newUser, pImg: file });
      console.log("files", file);
  
      const imageURL = URL.createObjectURL(file);
      setPreviewImage(imageURL);
    };
    
    const handleSubmit=(e:any)=>{
      e.preventDefault()
      const formData:any=new FormData();
      formData.append("title", title);
formData.append("description",description);
formData.append("blogImg", newUser.pImg);
formData.append("content",content);

      CreateNewBlog(formData,cookie)
      .then((Result:any)=>{
        console.log(Result)
        notifySuccess(Result.message)
        setActiveTab(()=>1)
        setUpdata(true)

      })
      .catch((err:any)=>{
        console.log(err)
      })
     console.log(content)
    }
 return(<> 
 
 <div className={style.BlgContainer}>
  <form className={style.BlgContent} onSubmit={handleSubmit}>
    <div className={style.blgConleft}>
      <h6>Add Blog Body</h6>
    <ReactQuill value={editorHtml} onChange={handleChange} className={style.blogContent}/>
        
    </div>
    <div className={style.blgConright}>
    <h4>Create a Blog Post</h4>
  
    <div className={styles.ProgramProp}>
                    <div className={styles.onlinePro} >
                    <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
                onChange={handleProfile}
              id="fileInput"
              name="profile"
            />

            {!previewImage ? (
              <>
                <img
                  src="/images/Admin/unnamed.jpg"
                  className={styles.MainImgPre}
                />
                <div className={styles.IconImg}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    onClick={handleProfileClick}
                  />
                </div>
              </>
            ) : (
              <>
                <img
                  src={previewImage}
                  alt="Preview"
                  className={styles.MainImgPre1}
                />
                <div className={styles.IconImg1}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    onClick={handleProfileClick}
                  />
                </div>
              </>
            )}
            </div>
            </div>
    <TextField
            type="text"
            id="outlined-basic"
            label="Blog Topic"
            variant="outlined"
            name="title "
            value={title}
            onChange={(e: any) => setBhead(e.target.value)}
            className={style.Inputfield}
            InputProps={{
              className: `${style.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
          
          <TextArea
            id="outlined-basic"
            placeholder="Blog Description"
            name="description"
           
            value={description}
            onChange={(e: any) => setBdesc(e.target.value)}

            rows={5}
          />
  
   
    <button className={style.blogContentBtn}  >Submit</button>
    </div>
    </form>
  </div>
 </>)   
}
export default CreateBlog;