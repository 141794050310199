import { useState } from "react";
import style from "../../../../styles/setting.module.css";
import { TextField } from "@mui/material";

const SetTab2 = () => {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isYesNo1, setisYesNo1] = useState(false);
  const [isYesNo2, setisYesNo2] = useState(false);

  const handleyesbox1Change = () => {
    setIsChecked1(!isChecked1);
    setIsChecked2(false);
  };

  const handleyesbox2Change = () => {
    setIsChecked2(!isChecked2);
    setIsChecked1(false);
  };
  const handlenobox1Change = () => {
    setisYesNo1(!isYesNo1);
    setisYesNo2(false);
  };

  const handlenobox2Change = () => {
    setisYesNo2(!isYesNo2);
    setisYesNo1(false);
  };
  return (
    <>
      <div className={style.tabcontainer}>
        <header className={style.tabheader}>
          Coach and Related Certification
        </header>
        <div className={style.tabformcontent}>
          <div className={style.leftabcontent}>
            <header>Coaching Certificate</header>
            <div className={style.choicetag}>
              <label>
                <input
                  type="checkbox"
                  checked={isChecked1}
                  onChange={handleyesbox1Change}
                  className={style.YesNo}
                />
                Yes
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={isChecked2}
                  onChange={handleyesbox2Change}
                  className={style.YesNo}
                />
                No
              </label>
            </div>
            <div className={style.inputtab2}>
              <TextField
                type="text"
                id="outlined-basic"
                label="Coach Training School"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="Specialty Experience/Sweet Spot"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />
            </div>
          </div>
          <div className={style.rightabcontent}>
            <header>Understanding of coaching ethics</header>
            <div className={style.choicetag}>
              <label>
                <input
                  type="checkbox"
                  checked={isYesNo1}
                  onChange={handlenobox1Change}
                  className={style.YesNo}
                />
                Yes
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={isYesNo2}
                  onChange={handlenobox2Change}
                  className={style.YesNo}
                />
                No
              </label>
            </div>
            <div className={style.inputtab2}>
              <TextField
                type="text"
                id="outlined-basic"
                label="Other Certifications"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />
            </div>
          </div>
        </div>
        <div className={style.formbutton}>
          <button>Update</button>
        </div>
      </div>
    </>
  );
};

export default SetTab2;
