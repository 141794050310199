import { Box, TextField } from "@mui/material";
import style from "../../../../styles/setting.module.css";
import styles from "./settings.module.css"
type propType = {
    setActiveTab: (val: (v: number) => number) => void;
  };
const SetTab2=({ setActiveTab }: propType)=>{
return (<>

<div className={style.tabcontainer}>
      <div className={styles.changpassContent}>
        <div className={style.changepass}>
          <header className={style.changepwdhead}>Change Password</header>
          <div className={style.forminput4}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="full-width-text-field"
                type="Password"
                label="Old-Password"
                sx={{ my: 2, width: "100%" }}
                variant="standard"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="full-width-text-field"
                type="password"
                label="New Password"
                sx={{ my: 2, width: "100%" }}
                variant="standard"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="full-width-text-field"
                type="password"
                label="Confirm Password"
                sx={{ my: 2, width: "100%" }}
                variant="standard"
              />
            </Box>
          </div>
        </div>
        <div className={style.formbutton}>
          <button>Update</button>
        </div>
       </div>
      </div>
</>)
}
export default SetTab2;