import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { fetchCoachByCoachee } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { notifyError } from "../../../Notify";
import ShowCoach from "./Recommend/ShowCoach";
type propType={
  setSubActiveTab:(val: (v: number) => number) => void;
}
const Recommend = ({setSubActiveTab}:propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const[coachData,seCoachData]=useState<any[]>([]);
  const[activeTab,setActiveTab]=useState(0);
  const[coachId,setCoachId]=useState("")

  useEffect(()=>
  {
    fetchCoachByCoachee(cookie)
    .then((result: any) => {
    console.log(result)
    seCoachData(result.data.history)

    
    })
    .catch((error: any) => {
      console.error("Error fetching coach data:", error);
      notifyError(error);
    });
}, [cookie]);
const handleShow=(uId:any)=>{
  setActiveTab(1)
  setCoachId(uId)
  
}

  return (
    <>
    {activeTab==0 && (<>
    
      <div className={styles.dashcontainer}>
        <div className={styles.UpcomingContainer}>
        <div className={styles.UpcomingP}>
              <p>All Recommended Coaches list</p>
            </div>
            
            </div>
            <div className={styles.DashForRecom}>
            <div className={styles.Recommond}>
         {coachData.length>0?(coachData.map((cdata:any)=><>
         
        
          <div className={styles.coaching}>
          <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.uid.profileImg}`}
                        className={styles.coachingImg}
                      />
              <div className={styles.coachingTxt} onClick={() => handleShow(cdata.uid._id)}>
                <p>{cdata.uid.name}</p>
                <div className={styles.category}>
                 
                 <span> {cdata.behavioralIdentity.slice(0, 2)}</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>{cdata.uid.email.slice(0,18)}..</span>
                </div>
              </div>
              </div>
          


         </>)):(<>No coach Data Found!</>)}
        </div>
        </div>
       </div>
          

    </>)} {activeTab===1 &&( <ShowCoach setActiveTab={setActiveTab} coachId={coachId} />)}
  
   
    </>
    
  );
};  

export default Recommend;
