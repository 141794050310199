import { useEffect, useState } from "react";
import style from "./wrapper.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/userSlice";
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { RootState } from "../../../../redux/store";
type propType = {
  activeTab: number;
  setActiveTab: (val: number) => void;
  setHead:any;
};
function SideBar({ activeTab, setActiveTab,setHead }: propType) {
  const user = useSelector((state: RootState) => state.user.user);
  console.log("profile", user?.name);
  const handleClick = (index: number) => {
    setActiveTab(index);
  };
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const [showSidebar, setShowSidebar] = useState(true);
 
  const renderHead=()=>{
    if(activeTab===1){
      setHead(`Welcome Dashboard ${user?.name}`);
    }
    else if(activeTab===2){
      setHead(`Upcoming Coachings`);
    }
    else if(activeTab===3){
      setHead(`My Coaches`)
    }
    else if(activeTab===4){
      setHead(`Recommendations`)
    }
    else if(activeTab===5){
      setHead(`My Reports`)
    }
    else if(activeTab===7){
      setHead(`My Settings `)
    }
    else if(activeTab==8){
      setHead(`Assessment`)
    }
    else if(activeTab==9){
      setHead(`My Certificates`)
    }
    else if(activeTab==10){
      setHead(`FAQ's`)
    }
    else if(activeTab==12){
      setHead(`My Groups`)
    }
  }
  useEffect(()=>{
    renderHead()
  },[activeTab])
  return (
    <>
      {/* main content */} <button onClick={toggleSidebar} className={style.toggleButton}>
        {showSidebar ? (
          <ViewSidebarIcon className={style.HideButton} />
        ) : (
          <ViewSidebarIcon />
        )}
      </button>
     
      <div className={style.sidebarContainer}>
        <div className={style.MenuToggleOpen}>
          <ViewSidebarIcon />
        </div>
        {showSidebar ? (
      <div className={style.sidebar}>
        <div className={style.smartlogo}>
          <div className={style.smarthlogo1}   >
            <img
              src="./images/Coach/login/white-logo.png"
              className={style.whitebg}
            />
          </div>
          <div className={style.siderbarcontent}>
            <div className={style.content}>
              <div className={style.profile}  onClick={() => handleClick(11)}>
                {/* profile css  */}
                {/* profileImg */}
                {/* <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${user?.profileImg}`} className={style.profileImg}/>
                 */}
                   <img src="./images/Coach/login/Shape.png" className={style.profileImg}/>
                <div className={style.uname}>
                  <h2>John Doe</h2>
                  <p>acgduihs@gmail.com</p>
                </div>
              </div>
              <div className={style.contentitem}>
                <ul className={style.tabCnt}>
                  <li
                    onClick={() => handleClick(1)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 1 && style.lidesign}`}>
                      {activeTab === 1 ? (
                        <img src="./images/Coach/login/dashboardwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/dashboard.svg" />
                      )}
                      Dashboard
                    </div>
                  </li>

                  <li
                    onClick={() => handleClick(2)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 2 && style.lidesign}`}>
                      {activeTab === 2 ? (
                        <img src="./images/Coach/login/sessionwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/session.svg" />
                      )}
                      Upcoming Coaching
                    </div>
                  </li>

                  <li
                    onClick={() => handleClick(3)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 3 && style.lidesign}`}>
                      {activeTab === 3 ? (
                        <img src=" /images/svg/Coachee/myCoachWhite.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/myCoach.svg" />
                      )}
                      My Coaches
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(6)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 6 && style.lidesign}`}>
                      {activeTab === 6 ? (
                        <img src="/images/svg/Coachee/whiteMyRepo.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/report.svg" />
                      )}{" "}
                    All Programs
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(12)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 12 && style.lidesign}`}>
                      {activeTab === 12 ? (
                          <img src="./images/Coach/login/groupwhite.svg" />
                        ) : (
                          <img src="./images/Coach/login/group.svg" />
                      )}{" "}
                    Group Engagement
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(4)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 4 && style.lidesign}`}>
                      {activeTab === 4 ? (
                        <img src="/images/svg/Coachee/whitelike.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/Like.svg" />
                      )}{" "}
                      Recommendations
                    </div>
                  </li>

                  <li
                    onClick={() => handleClick(5)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 5 && style.lidesign}`}>
                      {activeTab === 5 ? (
                        <img src="/images/svg/Coachee/whiteMyRepo.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/report.svg" />
                      )}{" "}
                      My Reports
                    </div>
                  </li>
                 
                  <li
                    onClick={() => handleClick(7)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 7 && style.lidesign}`}>
                      {activeTab === 7 ? (
                        <img src="./images/Coach/login/settingwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/settings.svg" />
                      )}{" "}
                      My Settings
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(8)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 8 && style.lidesign}`}>
                      {activeTab === 8 ? (
                        <img src="/images/svg/Coachee/whiteSub.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/myAssess.svg" />
                      )}{" "}
                      Assessment
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(9)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 9 && style.lidesign}`}>
                      {activeTab === 9 ? (
                        <img src="/images/svg/Coachee/whiteMyCerti.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/myCertificate.svg" />
                      )}{" "}
                      My Certificates
                    </div>
                  </li>
                </ul>
                <hr />
                <div className={style.about}>
                  <ul>
                    <li>About us</li>
                    <li>How it works</li>
                    <li>Resources and articles</li>
                    <li onClick={() => dispatch(logout())}>logout</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       ) : (
        <div className={style.Sidebar2}></div>
      )}
      </div>
    </>
  );
}

export default SideBar;
