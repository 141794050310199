import React, { useState, useEffect } from "react";
import style from "../../../../../styles/setting.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { TextField } from "@mui/material";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import AddIcon from "@mui/icons-material/Add";
import { getAntStyleList } from "../timezones";
const SetActivites = () => {
  const currentDate = new Date();
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = days[currentDate.getDay()];
  const locationOptions = getAntStyleList().map((location, index) => (
    <option key={index} value={location.value}>
      {location.label}
    </option>
  ));
  const formattedDate = `${currentDay},${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;

  const [inputFields, setInputFields] = useState<string[]>([]);
  const addInputField = () => {
    setInputFields([...inputFields, ""]);
  };
  console.log("Input Fields:", inputFields);

  const removeInputField = (index: any) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields.splice(index, 1);
    setInputFields(updatedInputFields);
  };
  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields[index] = event.target.value;
    setInputFields(updatedInputFields);
  };
  return (
    <>
      <div className={style.HeadContainer}>
        <header className={style.tabheader}>Set Availability </header>
        <div className={style.sideCal}>
          <CalendarMonthIcon className={style.sideCal1} />
          {formattedDate}
        </div>
      </div>
      <div className={style.setAvailableContainer}>
        <div className={style.setAvailable}>
          <div className={style.timeContainer}>
            {inputFields.map((value, index) => (
              <div key={index} className={style.timeslot}>
                <div className={style.AddSlot}>
                  <div className="inputslot">
                    <TextField
                      type="text"
                      value={value}
                      label="Enter Slot"
                      variant="standard"
                    />
                    <br />
                    <TextField type="time" variant="standard" />
                    <span className={style.Totime}>To</span>
                    <TextField type="time" variant="standard" />
                    <br />

                    <select id="location" className={style.SelectLocat}>
                      {locationOptions}
                    </select>
                    <label className={style.SelectLocatLabel}>
                      Add Your Time Zone
                    </label>
                    <br />
                  </div>
                  <div className={style.deleteSlot}>
                    <button onClick={() => removeInputField(index)}>
                      <DeleteSweepIcon />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <button onClick={addInputField} className={style.eventslot}>
          <AddIcon className={style.eventSlotIcon} />
          <span>Add Slot</span>
        </button>
      </div>
    </>
  );
};
export default SetActivites;
