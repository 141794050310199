import { useState } from "react";
import SetTab1 from "./Settings/SetTab1";
import SetTab2 from "./Settings/SetTab2";
import styles from "./Settings/settings.module.css"
const Settings=() =>{
  const [activeTab,setActiveTab]=useState(1)
  return (
    <div className={styles.Setcontainer}>
      <h3>Settings</h3>
      <hr/>
      <div className={styles.profileContent}>
      <div className={styles.leftContainer}>
        <ul>
        {/* <li onClick={()=>setActiveTab(1)} className={styles.tabset}>Edit profile</li> */}
        <li onClick={() => setActiveTab(1)} className={styles.setli}>
                  <div className={`${activeTab === 1 && styles.setliactive}`}>
                    {activeTab === 1 ? (
                      <img src="/images/svg/Coach/setting/colorpersonal.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/personal.svg" />
                    )}
                    Edit Profile
                  </div>
                </li>
                <li onClick={() => setActiveTab(2)} className={styles.setli}>
                  <div className={`${activeTab === 2 && styles.setliactive}`}>
                  {activeTab === 2 ? (
                      <img src="/images/svg/Coach/setting/colorauthen.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/authenticate.svg" />
                    )}
                    Change Password
                  </div>
                </li>
        <li onClick={ ()=>setActiveTab(2)}></li>
        </ul>
        {/* 
         */}
      </div>
      <div className={styles.rightContainner}>
      {activeTab===1 && <SetTab1 setActiveTab={setActiveTab}/>}
      {activeTab === 2 && <SetTab2 setActiveTab={setActiveTab}/>}
      </div>

      </div>


    </div>
    
  )
}

export default Settings;