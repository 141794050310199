import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "../wrapper/wrapper.module.css";
import style from "../programs/programs.module.css";
import { TextField } from "@mui/material";
import {  fetchCoach } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
type propType = {
  setActivescreen: (val: (v: number) => number) => void;
};
const CreateAGroup = ({ setActivescreen }: propType) => {
  const [coachData, setCoachData] = useState<any[]>([]);
  const [groupName, setGroupName] = useState("");
  const [assignedCoach, setassignedCoach] = useState<any[]>([]);
  const user = useSelector((state: RootState) => state.user.user);
  console.log("profile", user?.profileSetup);
  const cookie: any = user?.token;

  const handleAddCoach = (coachId: string) => {
    if (!assignedCoach.includes(coachId)) {
      setassignedCoach([...assignedCoach, coachId]);
    }
  };

  const handleRemoveCoach = (coachId: string) => {
    setassignedCoach(assignedCoach.filter((id) => id !== coachId));
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // CreateGroup(groupName, assignedCoach, cookie);
    // setActivescreen(() => 2);
  };
  useEffect(() => {
    fetchCoach(cookie,1)
      .then((result: any) => {
        console.log("Coach data fetch by offline:", result);
        setCoachData(result.data);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });
  }, [cookie]);

  return (
    <div className={styles.MainImg}>
      <form onSubmit={handleSubmit} className={style.recordContainer}>
        <TextField
          type="text"
          id="outlined-basic"
          label="Enter Group Name "
          variant="outlined"
          name="phoneNo"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          className={styles.Inputfield}
          InputProps={{
            className: `${styles.muiInput}`,
          }}
          sx={{
            mr: 1,
            my: 1,
            borderRadius: "20px",
            "& .MuiInputBase-input": {
              width: "100%",
            },
          }}
        />
        {coachData.length > 0 ? (
          coachData.map((coach: any, index: number) => (
            <>
              <ul key={index}>
                <li>
                  {" "}
                  <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                    className={style.coachImg}
                  />{" "}
                  {coach.uid.name}
                </li>
                <li>
                  {assignedCoach.includes(coach.uid._id) ? (
                    <button
                      className={style.RemoveBtn}
                      onClick={() => handleRemoveCoach(coach.uid._id)}
                      type="button"
                    >
                      <DeleteIcon />
                      Remove
                    </button>
                  ) : (
                    <button
                      className={style.AddBtn}
                      onClick={() => handleAddCoach(coach.uid._id)}
                      type="button"
                    >
                      <AddIcon />
                      Add
                    </button>
                  )}
                </li>
              </ul>
            </>
          ))
        ) : (
          <p>No coach data available</p>
        )}
        <button type="submit" className={style.AddBtn}>
          Save
        </button>
      </form>
    </div>
  );
};

export default CreateAGroup;
