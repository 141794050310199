import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import styles from "./css/AdminManage.module.css"
const AdminManagement=()=>{
    const user = useSelector((state: RootState) => state.user.user);
    console.log("profile", user?.name);
    return(<>
    <div className={styles.AdminManage}>
        <h6>Admin Management</h6>
    <div className={styles.AdminManageDisp}>
        <img src="./images"/>
    </div>
         </div>
    </>)
}
export default AdminManagement;