import { Box, Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
const MyReport=() =>{
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);

  return (
    <>
     <div className={styles.dashcontainer}>
        <div className={styles.upgradeTap}>
          <div className={styles.upgradeTxt}>
            <h3>Upgrade to</h3>
            <h2>Comprehensive Report</h2>
            <br />
            <h4>Features</h4>
            <p>- hello this is from your dashboard</p>
            <p>- hello this is from your dashboard</p>
            <p>- hello this is from your dashboard</p>
          </div>
          <div className={styles.upgradeButton}>
            <Button onClick={handleOpen}> Upgrade Now</Button>
            <Modal
              open={open1}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleClose} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Make Payment
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={styles.typoDesc}
                >
                  <div className={styles.UpgradeDiv}>
                    <img src="./images/Coachee/placeholder.png" />
                    <div className={styles.UpgradeText}>
                      <br />
                      <h6>Upgrade to Comprehensive Report</h6>
                      <p>-Lorem Ipsum is simply dummy text of the printing.</p>
                      <p>-Lorem Ipsum is simply dummy text of the printing.</p>
                      <p>-Lorem Ipsum is simply dummy text of the printing.</p>
                    </div>
                  </div>
                  <hr />
                  <div className={styles.TotalPay}>
                    <h6>Total Payment</h6>
                    <h6> $250.00 </h6>
                  </div>
                  <br />
                  <Button
                    className={styles.TypoButton}
                    sx={{ m: 1, width: "100%" }}
                  >
                    Pay Now
                  </Button>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div className={styles.LearnMore}>
          <h5>Learn More</h5>
          <p>
            Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </p>
        </div>

        {/*                             Read More                             */}
         <div className={styles.UpcomingContainer}>
         <div className={styles.ShowLecture}>
         <div className={styles.Recommond}>
          <div className={styles.coaching}>
            
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
             
            
              <div className={styles.coachingTxt}>
               <p>Get Free Consultation</p>
               <span>
                Lorem ipsom is simple dummy text that can used for add dummy type to them 
                and that can helps for on that people ok ndjsd jdjs
               </span><br/>
               <Button className={styles.ConsultRead}>Read More</Button>
              </div>
            
             
              </div>
              {/* 2nd one */}
              <div className={styles.coaching}>
            
            <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
           
          
            <div className={styles.coachingTxt}>
             <p>Get Free Consultation</p>
             <span>
              Lorem ipsom is simple dummy text that can used for add dummy type to them 
              and that can helps for on that people ok ndjsd jdjs
             </span><br/>
             <Button className={styles.ConsultRead}>Read More</Button>
            </div>
          
           
            </div>
            {/* 3rd one */}
            <div className={styles.coaching}>
            
            <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
           
          
            <div className={styles.coachingTxt}>
             <p>Get Free Consultation</p>
             <span>
              Lorem ipsom is simple dummy text that can used for add dummy type to them 
              and that can helps for on that people ok ndjsd jdjs
             </span><br/>
             <Button className={styles.ConsultRead}>Read More</Button>
            </div>
          
           
            </div>
            {/* 4th one */}
            <div className={styles.coaching}>
            
            <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
           
          
            <div className={styles.coachingTxt}>
             <p>Get Free Consultation</p>
             <span>
              Lorem ipsom is simple dummy text that can used for add dummy type to them 
              and that can helps for on that people ok ndjsd jdjs
             </span><br/>
             <Button className={styles.ConsultRead}>Read More</Button>
            </div>
          
           
            </div>
          </div>
           
        </div>
        </div>
        </div>
    </>
  )
}

export default MyReport