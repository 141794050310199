import { TextField } from "@mui/material";
import style from "./settings.module.css"
import styles from "../wrapper/wrapper.module.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
interface NewUser {
  pImg: File | null;
}
const SetTab1 = ({ setActiveTab }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  console.log("profile", user?.name);
  const str: any = user?.name;
  const abc = str.split(" ");
  const [name, setName] = useState(abc[0]);
  const [lname, setLname] = useState(abc[1]);
  const [email, setEmail] = useState(user?.email)
  const [newUser, setNewUser] = useState<NewUser>({
    pImg: null,
  });
  const [previewImage, setPreviewImage] = useState("");
  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, pImg: file });
    console.log("files", file);

    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };
  return (<>
    <div className={style.changpassContent}>
      <div className={styles.ProgramProp}>
        <div className={style.onlineImg}>
          <div className={styles.onlinePro} >
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleProfile}
              id="fileInput"
              name="profile"
            />
            {!previewImage ? (
              <>
                <img
                  src="/images/Coach/shape-2x.png"
                  className={styles.progProfile}
                />
                <div className={styles.IconImg}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    onClick={handleProfileClick}
                  />
                </div>
              </>
            ) : (
              <>
                <img
                  src={previewImage}
                  alt="Preview"
                  className={styles.MainImgPre}
                />
                <div className={styles.IconImg}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    onClick={handleProfileClick}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={style.textInputContent}>
          <div className={style.textInputleft}>
            <TextField
              type="text"
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              className={style.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,

              }}
              sx={{
                my: 1,
                "& .MuiInputBase-input": {
                  width: "100%",

                },
                "& .MuiInputLabel-root": {
                  backgroundColor: "#fff",
                },
              }}
            />
            <TextField
              type="email"
              id="outlined-basic"
              label="Email Address"
              variant="outlined"
              name="name"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              className={style.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,

              }}
              sx={{
                my: 1,
                "& .MuiInputBase-input": {
                  width: "100%",

                },
                "& .MuiInputLabel-root": {
                  backgroundColor: "#fff",
                },
              }}
            />
          </div>
          <div className={style.textInputright}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              name="Last Name"
              value={lname}
              onChange={(e: any) => setLname(e.target.value)}
              className={style.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,

              }}
              sx={{
                my: 1,
                "& .MuiInputBase-input": {
                  width: "100%",

                },
                "& .MuiInputLabel-root": {
                  backgroundColor: "#fff",
                },
              }}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="Contact Number"
              variant="outlined"
              name="name"
              className={style.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,

              }}
              sx={{
                my: 1,
                "& .MuiInputBase-input": {
                  width: "100%",

                },
                "& .MuiInputLabel-root": {
                  backgroundColor: "#fff",
                },
              }}
            />
          </div>

        </div>
        <div className={style.SaveBtnConte}>
          <button className={styles.SaveButton}>Update</button>
        </div>
      </div>
    </div>
  </>)
}
export default SetTab1;