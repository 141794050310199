import { useEffect, useState } from "react";
import styles from "./group.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { fetchCoachGroupID } from "../../../../Api";
import { notifyError } from "../../../../Notify";
import Style from "../../../../../src/styles/group.module.css";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  groupId:any
};
const GeTab2 = ({ setActiveTab,groupId }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const[coachee,setCoachee]=useState<any[]>([]);
  const[groupTopic,setGroupT]=useState("")
  const[createdAt,setcreatedAt]=useState("")
 
  useEffect(()=>{
    fetchCoachGroupID(groupId,cookie)
    .then((result: any) => {
      console.log("Fetch group By ID data:", result.data);
      setGroupT(result.data.groupTopic)
      
    
    })
    .catch((error: any) => {
      console.error("Error fetching Group data:", error);
      notifyError(error);
    });
  },[cookie])
  
  return (
    <>
      <div className={styles.maincontainer}>
      <div className={styles.groupHeaders}>
     <div className={styles.groupHeadLeft}>
     <h5>{groupTopic}</h5>
     </div>
     <div className={styles.groupHeadLeft}>
     <p>Total Session</p>
     </div>
      </div>
      <div>
        <table>
          <tr>
           <th> SESSION TOPIC</th>
           <th>WHAT ARE WE GOING TO LEARN</th>
           <th>SESSION DURATION</th>
           <th>DATE</th>
           <th>STATUS</th>
           <th></th>
          </tr>
        </table>
      </div>

        <div>

                  </div>
      </div>
    </>
  );
};

export default GeTab2;
