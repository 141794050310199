import styles from "./session.module.css";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const AsTab4 = ({ setActiveTab }: propType) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.container1}>
          <input
            className={styles.container2}
            type="text"
            placeholder="Which client are you looking for"
            style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
          />
          <div>
            <table className={styles.sessiontable}>
              <tr>
                <th> CLIENT NAME</th>
                <th> REQUEST TYPE</th>
                <th> REQUESTS</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td className={styles.img_textt}>
                  <div>
                    <img src="/images/Coach/pic1.png" alt="" />
                  </div>
                  <div className={styles.namess}>
                    <div style={{ fontSize: "14px" }}>John Kameron Deo</div>
                    <div>
                      <p style={{ fontSize: "9px", color: "orange" }}>
                        Facilitator/Fast-Paced/Outging
                      </p>
                    </div>
                  </div>
                </td>
                <td>Requests Name</td>
                <td>Lorem ipsum dolor sit amet consecteture.Lorem ipsum</td>
                <td>
                  <button
                    className={styles.buttonn}
                    onClick={() => setActiveTab((prev) => prev + 1)}
                  >
                    Approve Requests
                  </button>
                </td>
                <td>
                  <button
                    className={styles.button2}
                    onClick={() => setActiveTab((prev) => prev + 1)}
                  >
                    <img src="/images/svg/Coach/bluebutton.svg" alt="" />
                  </button>
                </td>
                <td>
                  <button
                    className={styles.button3}
                    onClick={() => setActiveTab((prev) => prev + 1)}
                  >
                    <img src="/images/svg/Coach/bluebutton.svg" alt="" />
                  </button>
                </td>
              </tr>
              <tr>
                <td className={styles.img_textt}>
                  <div>
                    <img src="/images/Coach/pic2.png" alt="" />
                  </div>
                  <div className={styles.namess}>
                    <div style={{ fontSize: "14px" }}>John Kameron Deo</div>
                    <div>
                      <p style={{ fontSize: "9px", color: "orange" }}>
                        Facilitator/Fast-Paced/Outging
                      </p>
                    </div>
                  </div>
                </td>
                <td>Requests Name</td>
                <td>Lorem ipsum dolor sit amet consecteture.Lorem ipsum</td>
                <td>
                  <button
                    className={styles.buttonn}
                    onClick={() => setActiveTab((prev) => prev + 1)}
                  >
                    Approve Requests
                  </button>
                </td>
                <td>
                  <button
                    className={styles.button2}
                    onClick={() => setActiveTab((prev) => prev + 1)}
                  >
                    <img src="/images/svg/Coach/bluebutton.svg" alt="" />
                  </button>
                </td>
                <td>
                  <button
                    className={styles.button3}
                    onClick={() => setActiveTab((prev) => prev + 1)}
                  >
                    <img src="/images/svg/Coach/bluebutton.svg" alt="" />
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default AsTab4;
