import style from "./chat.module.css";
import AddIcon from "@mui/icons-material/Add";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  GetAllMsg,
  GetTickets,
  InitilizeTicket,
  SendMsg,
  GetAllChats
} from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const Chat = () => {
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  // const [ticket, setticket] = useState<any>({ description: "", issue: "" });
  const [msg, setmsg] = useState<any>("");
  const [activechat, setactivechat] = useState<any>("");
  const [messages, setmessages] = useState<any>([]);
  const[issue,setIssue]=useState("");
  const[description,setdescription]=useState("")
  const [viewTicket, setviewTicket] = useState<any>([]);
const[chId,setchId]=useState('')
  const RaiseTicket = async () => {
    try {
      const data = await InitilizeTicket(cookie, description,issue);
      console.log(data);
      setOpen1(false);
      setmsg('')
      setIssue('')
      
      FetchAllTickets(); // Fetch tickets again to update the list
    } catch (error) {
      console.log("Error At Creating Ticket", error);
    }
  };

  const GetMsgs = async (chatId: any) => {
    setactivechat(chatId);
    try {
      const data: any = await GetAllMsg(cookie, chatId);
      setmessages(data.history);
      // GetAllChats(cookie,chatId)
      // .then((result)=>{console.log(result)})
     
      console.log("data of chatroom",data)
    } catch (error) {
      console.log("Error At Fetching Messages", error);
    }
  };

  const SendMsgs = async () => {
    try {
      const data = await SendMsg(cookie, msg, activechat);
      setmsg("");
      GetMsgs(activechat); // Fetch messages again to update the chat
    } catch (error) {
      console.log("Error At Sending Message", error);
    }
  };
  

  const FetchAllTickets = async () => {
    try {
      // GetAllChats
      const data: any = await GetTickets(cookie);
      if (!data.data) {
        console.log("No Tickets");
      } else {
        setviewTicket(data.data);
        console.log("Tickets Set", data.data.length);
        console.log("ticket Data",data.data)
      }
    } catch (error) {
      console.log("Error At Fetching Tickets", error);
    }
  };

  useEffect(() => {
    FetchAllTickets();
  }, []);

  return (
    <div className={style.ChatContainer}>
      <div className={style.Chatparts}>
        <div className={style.ChatpartsLeft}>
          <div className={style.Chathead}>
            <h4> Ticket</h4>
            <button onClick={handleOpen}>
              <AddIcon />
              New
            </button>
            <Modal
              open={open1}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Create Ticket
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={style.typoDesc}
                >
                  <form>
                 
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="Ticket Description"
                      variant="outlined"
                      value={description}
                      onChange={(e: any) => {
                        setdescription(e.target.value);
                      }}
                      className={style.Inputfield}
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",
                          
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
              },
                      }}
                    />
                    <textarea
                      placeholder="Describe Your Issue"
                      className={style.InputTextfield}
                      rows={6}
                      value={issue}
                      onChange={(e: any) => {
                        setIssue(e.target.value);
                      }}
                     
                    ></textarea>
                    <p className={style.TypoButton} onClick={RaiseTicket}>
                      Create New Ticket
                    </p>
                  </form>
                </Typography>
              </Box>
            </Modal>
          </div>
          {viewTicket && viewTicket.length > 0 ? (
            <>
              {viewTicket.map((ticket: any) => (
                <div
                  key={ticket._id}
                  onClick={() => {
                    GetMsgs(ticket.chatId);
                  }}
                  className={style.TicketDesc}
                >
               
                  <p>{ticket.title}</p>
                  <span>{ticket.description.slice(0,16)}</span>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={style.ChatpartsRight}>
          <div>
            {messages && messages.length > 0 ? (
              <>
                {messages.map((msg: any, index: number) => (
                  <div key={index} className={style.DisplayMsg}>
                    {msg.from === user?._id ? (
                      <div className={style.msgTextLeft}>
                        <p>{msg.text}</p>
                      </div>
                    ) : (
                      <div className={style.msgTextRight}>
                        <p>{msg.text}</p>
                      </div>
                    )}
                    {/* <p>{msg.from}</p> */}
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={style.SendMsgButton}>
            <input
              type="text"
              value={msg}
              onChange={(e: any) => setmsg(e.target.value)}
            />
            <button onClick={SendMsgs}>
              <SendIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
