import styles from "../../../styles/dashboardHome.module.css";
const Home = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        {/* first */}
        <div className={styles.firstContainer}>
          <div className={styles.menuesBox}>
            <div className={styles.item}>
              {/* box 1 */}
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  CLIENT
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  src="/images/Coach/dash-user.png"
                  alt=""
                />
              </div>
              <div>
                <h5>450</h5>
              </div>
              <div className={styles.corner}>
                <img src="/images/Coach/dash-upArrow.png" alt="" />
                <span> +5.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 2 */}
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  SESSIONS
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  src="/images/Coach/carbon_headphones.png"
                  alt=""
                />
              </div>
              <div>
                <h5>56</h5>
              </div>
              <div>
                <img src="/images/Coach/dash-upArrow.png" alt="" />
                <span> +5.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 3 */}
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  COACHING HOURS
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  style={{ width: "24px" }}
                  src="/images/Coach/hour-glass.png"
                  alt=""
                />
              </div>
              <div>
                <h5>1299</h5>
              </div>
              <div>
                <img src="/images/Coach/down-arrow-red.png" alt="" />
                <span> -6.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 4 */}
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  EARNINGS THIS MONTH
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  style={{ height: "33px" }}
                  src="/images/Coach/money-bag.png"
                  alt=""
                />
              </div>
              <div>
                <h5>$1250</h5>
              </div>
              <div className={styles.corner}>
                <img src="/images/Coach/down-arrow-red.png" alt="" />
                <span> -6.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
          </div>
          <div className={styles.ChartGraph}>
            <div className={styles.headLine}>
              <h5>Total Sessions</h5>{" "}
            </div>
            <div>
              <div className={styles.selectBar}>
                <select name="year" id="year">
                  <option value="year">This Year</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* second */}
        <div className={styles.middleContainer}>
          <div className={styles.headerLine}>
            <div>
              <h3 style={{ fontSize: "24px" }}>
                {" "}
                <img src="/images/Coach/rectangle-shape.png" alt="" /> Your
                Client list
              </h3>
            </div>
            <div>
              <h4 style={{ color: "#1C74BC", fontSize: "16px" }}>View All</h4>
            </div>
          </div>
          <div className={styles.tables}>
            <table>
              <thead>
                <tr>
                  <th>client name</th>
                  <th>session type</th>
                  <th>duration</th>
                  <th>earnings</th>
                  <th>first session</th>
                  <th>last session</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.img_text}>
                    <div>
                      <img src="/images/Coach/pic-placeholder.png" alt="" />
                    </div>
                    <div className={styles.names}>Sachin das</div>
                  </td>

                  <td>Type here</td>
                  <td>15 Min</td>
                  <td>$50.00</td>
                  <td>May 18,2024 10.40 AM</td>
                  <td>May 19,2024 10.40 AM</td>
                </tr>
                <tr>
                  <td className={styles.img_text}>
                    <div>
                      <img src="/images/Coach/pic-placeholder2.png" alt="" />
                    </div>
                    <div className={styles.names}>neha r</div>
                  </td>
                  <td>Type here</td>
                  <td>30 Min</td>
                  <td>$70.00</td>
                  <td>jun 18,2024 10.40 AM</td>
                  <td>jun 19,2024 10.40 AM</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* third */}
        <div className={styles.thirdContainer}>
          <div className={styles.headerLine}>
            <div>
              <h3 style={{ fontSize: "24px" }}>
                {" "}
                <img src="/images/Coach/rectangle-shape.png" alt="" /> Upcoming
                Appointments
              </h3>
            </div>
            <div>
              <h4 style={{ color: "#1C74BC", fontSize: "16px" }}>View All</h4>
            </div>
          </div>
          <div className={styles.tables}>
            <table>
              <thead>
                <tr>
                  <th>client name</th>
                  <th>session type</th>
                  <th>duration</th>
                  <th>earnings</th>
                  <th>date & time</th>
                  <th>reschedule</th>
                  <th>action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.img_text}>
                    <div>
                      <img src="/images/Coach/pic-placeholder.png" alt="" />
                    </div>
                    <div className={styles.names}>Sachin das</div>
                  </td>
                  <td>Type here</td>
                  <td>15 Min</td>
                  <td>$50.00</td>
                  <td>31 jan 2023 10.00 AM-11.00 AM</td>
                  <td>
                    <img src="/images/Coach/reschedule.png" alt="" />
                  </td>
                  <td>
                    <img src="/images/Coach/action.png" alt="" />
                  </td>
                </tr>
                <tr>
                  <td className={styles.img_text}>
                    <div>
                      <img src="/images/Coach/pic-placeholder2.png" alt="" />
                    </div>
                    <div className={styles.names}>neha r</div>
                  </td>
                  <td>Type here</td>
                  <td>30 Min</td>
                  <td>$70.00</td>
                  <td>31 jan 2023 10.00 AM-11.00 AM</td>
                  <td>
                    <img src="/images/Coach/reschedule.png" alt="" />
                  </td>
                  <td>
                    <img src="/images/Coach/action.png" alt="" />
                  </td>
                </tr>
                <tr>
                  <td className={styles.img_text}>
                    <div>
                      <img src="/images/Coach/pic-placeholder.png" alt="" />
                    </div>
                    <div className={styles.names}>priya j</div>
                  </td>
                  <td>Type here</td>
                  <td>30 Min</td>
                  <td>$70.00</td>
                  <td>31 jan 2023 10.00 AM-11.00 AM</td>
                  <td>
                    <img src="/images/Coach/reschedule.png" alt="" />
                  </td>
                  <td>
                    <img src="/images/Coach/action.png" alt="" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
