import styles from "./setup.module.css";
import { ReactElement, useState } from "react";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { certificates } from "../../Api";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const CpTab2 = ({ setActiveTab }: propType) => {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isYesNo1, setisYesNo1] = useState(false);
  const user = useSelector((state: RootState) => state.user.user);
  console.log("cpTab2 ", user);
  console.log(user?.token);
  const cookie: any = user?.token;

  const [multiForms, setMultiForms] = useState<any[]>([
    {
      coachSchool: "",
      speciality: "",
      othercerti: "",
      certificateImg: null,
    },
  ]);

  const deleteForm = (indexToDelete: any) => {
    setMultiForms((prevForms: any) =>
      prevForms.filter((multiForms: any, index: any) => index !== indexToDelete)
    );
  };
  const addForm = () => {
    setMultiForms([
      ...multiForms,
      {
        coachSchool: "",
        speciality: "",
        othercerti: "",
        certificateImg: null,
      },
    ]);
  };

  const handleToggle = (type: string, checkboxNumber: number) => {
    if (type === "yesbox") {
      setIsChecked1(checkboxNumber === 1 ? true : false);
    } else if (type === "nobox") {
      setisYesNo1(checkboxNumber === 1 ? true : false);
    }
  };
  const certified: any = isChecked1;
  const ethics: any = isYesNo1;

  const [arrayValues, setArrayValues] = useState<string[]>([]);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // const speciality: any = special
    //   .split(",")
    //   .map((value: any) => value.trim());
    // setArrayValues(speciality);
    // const othercertificates: any = othercerti
    //   .split(",")
    //   .map((value: any) => value.trim());
    // setArrayValues(othercertificates);

    const formData = new FormData();

    formData.append("certified", certified);
    formData.append("ethics", ethics);

    multiForms.forEach((form, index) => {
      formData.append(`certificates[${index}][coachSchool]`, form.coachSchool);
      formData.append(`certificates[${index}][speciality]`, form.speciality);
      formData.append(`certificateImg`, form.certificateImg);
    });

    certificates(formData, cookie)
      .then((result: any) => {
        console.log(result);
        setActiveTab((prev) => prev + 1);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className={styles.mainContainer}>
        <h3>Coaching Certification </h3>
        <br />

        <div className={styles.inputContent}>
          <div className={styles.LeftInputField}>
            <b>Coaching Certification</b>

            <div className={styles.choicetag}>
              <label>
                <input
                  type="checkbox"
                  checked={isChecked1}
                  onChange={() => handleToggle("yesbox", 1)}
                  className={styles.YesNo}
                />
                Yes
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={!isChecked1}
                  onChange={() => handleToggle("yesbox", 2)}
                  className={styles.YesNo}
                />
                No
              </label>
            </div>
          </div>

          <div className={styles.RightInputField}>
            <b>Understanding of coaching ethics</b>

            <div className={styles.choicetag}>
              <label>
                <input
                  type="checkbox"
                  checked={isYesNo1}
                  onChange={() => handleToggle("nobox", 1)}
                  className={styles.YesNo}
                />
                Yes
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={!isYesNo1}
                  onChange={() => handleToggle("nobox", 2)}
                  className={styles.YesNo}
                />
                No
              </label>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputFieldContent}>
            {certified && (
              <>
                {multiForms.map((form, index) => (
                  <div key={index} className={styles.InputDataContainer}>
                    {index > 0 && (
                      <DeleteForeverIcon
                        onClick={() => deleteForm(index)}
                        className={styles.multiFmIcon}
                      />
                    )}
                    <div className={styles.leftInputData}>
                      <TextField
                        type="text"
                        id="outlined-basic"
                        label="Coach Training School"
                        variant="outlined"
                        name="coachSchool"
                        value={form.coachSchool}
                        onChange={(e) => {
                          const newForms = [...multiForms];
                          newForms[index].coachSchool = e.target.value;
                          setMultiForms(newForms);
                        }}
                        className={styles.Inputfield}
                        InputProps={{
                          className: `${styles.muiInput}`,
                        }}
                        sx={{
                          mr: 1,
                          my: 1,
                          borderRadius: "20px",
                          "& .MuiInputBase-input": {
                            width: "100%",
                          },
                        }}
                      />
                      <TextField
                        type="text"
                        id="outlined-basic"
                        label="Specialty Experience/Sweet Spot"
                        variant="outlined"
                        name="special"
                        value={form.speciality}
                        onChange={(e) => {
                          const newForms = [...multiForms];
                          newForms[index].speciality = e.target.value;
                          setMultiForms(newForms);
                        }}
                        className={styles.Inputfield}
                        InputProps={{
                          className: `${styles.muiInput}`,
                        }}
                        sx={{
                          mr: 1,
                          my: 1,
                          borderRadius: "20px",
                          "& .MuiInputBase-input": {
                            width: "100%",
                          },
                        }}
                      />
                    </div>
                    <div className={styles.RightInputData}>
                      {/* <TextField
                        type="text"
                        id="outlined-basic"
                        label="Other Certifications"
                        variant="outlined"
                        name="othercerti"
                        value={form.othercerti}
                        onChange={(e) => {
                          const newForms = [...multiForms];
                          newForms[index].othercerti = e.target.value;
                          setMultiForms(newForms);
                        }}
                        className={styles.Inputfield}
                        InputProps={{
                          className: `${styles.muiInput}`,
                        }}
                        sx={{
                          mr: 1,
                          my: 1,
                          borderRadius: "20px",
                          "& .MuiInputBase-input": {
                            width: "100%",
                          },
                        }}
                      /> */}
                      <TextField
                        type="file"
                        name="certificateImg"
                        onChange={(e: any) => {
                          const newForms = [...multiForms];
                          newForms[index].certificateImg = e.target.files[0];
                          setMultiForms(newForms);
                        }}
                        className={styles.Inputfield}
                        InputProps={{
                          className: `${styles.muiInput}`,
                        }}
                        sx={{
                          mr: 1,
                          my: 1,
                          borderRadius: "20px",
                          "& .MuiInputBase-input": {
                            width: "100%",
                          },
                        }}
                      />
                    </div>
                  </div>
                ))}
                <div className={styles.Addbtn}>
                  <AddIcon
                    className={styles.AddbtnContainer}
                    onClick={addForm}
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.textcenter1}>
            <button className={styles.button4} type="submit">
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CpTab2;
