import TextArea from "antd/es/input/TextArea";
import styles from "./setup.module.css";
import { useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { administration } from "../../Api";
import { login, setProfileSetup } from "../../redux/userSlice";
import { UserType } from "../../@types/user";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const CpTab4 = ({ setActiveTab }: propType) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  console.log("cpTab3 ", user);
  const cookie: any = user?.token;
  const [Mprocess, setMprocess] = useState("");
  const [Wprocess, setWprocess] = useState("");
  let profileSetupValue: boolean;
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(Mprocess, Wprocess);
    administration(Mprocess, Wprocess, cookie)
      .then((result: any) => {
        console.log(result.data);
        let x: UserType = {
          ...result.data.user,
          token: result.data.token,
        };
      
        setActiveTab((prev) => prev + 1);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className={styles.mainContainer}>
        <h3>Administration </h3>
        <br />
        <form onSubmit={handleSubmit}>
          <div className={styles.LeftInputField}>
            <TextArea
              id="outlined-basic"
              placeholder="Process to maintain client confidentiality"
              className={styles.TextAreaField}
              rows={5}
              onChange={(e: any) => setMprocess(e.target.value)}
              value={Mprocess}
            />
            <TextArea
              id="outlined-basic"
              placeholder="What process do you use to follow timely contractor required submissions (i.e., invoices, reports…)style"
              className={styles.TextAreaField}
              rows={5}
              onChange={(e: any) => setWprocess(e.target.value)}
              value={Wprocess}
            />
          </div>
          <div className={styles.textcenter1}>
            <button className={styles.button4} type="submit">
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CpTab4;
