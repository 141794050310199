import { useEffect, useState } from "react";
import { fetchAProgram } from "../../../../Api";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import style from "./programs.module.css";
import { Button } from "antd";

type propType = {
  setActivescreen: (val: (v: number) => number) => void;
  setProgramId:(val: (v: any) => any) => void;
  
};
const DispProgram = ({ setActivescreen,setProgramId }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  console.log("profile", user?.profileSetup);
  const cookie: any = user?.token;
  
  const [showProgram, setShowProgram] = useState<any>([]);
  
  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toISOString().split("T")[0]; // Extracts the date part
  };
  const HandleProgram = ( ptype: any,programId : any) => {
   
    ptype === 1
    ? (setActivescreen(() => 2,))
    : (setActivescreen(() => 3,)); 
    ptype === 1
    ? (setProgramId(() => programId,))
    : (setProgramId(() => programId,));
    
  };
  

  useEffect(() => {
    fetchAProgram(cookie)
      .then((result: any) => {
        const sortedPrograms = result.data.sort((a: any, b: any) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
          setShowProgram(sortedPrograms);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });
  }, [cookie]);
  return (
    <>
    <div className={style.container}>
      <div className={style.programContent}>
        {showProgram.length > 0 ? (
          showProgram.map((program: any, index: number) => (
            <> 
 <div className={style.DisplayProg}>
                <div className={style.progContent} onClick={()=>HandleProgram(program.program_type,program._id)}>
                  <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${program.programImg}`}
                  />
                 
                  <div className={style.programText}>
                    <h6>{program.name}</h6>
                    <p>{program.description.substring(0,23)}...</p>
                
                    <p className={style.programDate}>
                      <CheckCircleIcon className={style.programDateIcon} />
                      {formatDate(program.createdAt)}
                    </p>
                 
                  </div>
                 
                </div>
              </div>
             
            </>
          ))
        ) : (
          <p>No coach data available</p>
        )}
      </div>
      </div>
    </>
  );
};

export default DispProgram;
