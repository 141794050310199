import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalContext } from './context/globalContext';
import { BrowserRouter } from 'react-router-dom';
import { store } from "./redux/store";
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>

    <BrowserRouter>
      <GlobalContext>
        <GoogleOAuthProvider clientId={'805778334328-rg6n4jap4iabs28pvqb8ecvl3r2ugo02.apps.googleusercontent.com'}>
          <App />
        </GoogleOAuthProvider>
      </GlobalContext>
    </BrowserRouter>

  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
