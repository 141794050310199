import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {  fetchCoacheeProgram } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { notifyError } from "../../../Notify";
import ShowProgram from "./Program/ShowProgram";
import LiveProgram from "./Program/LiveProgram";

type propType={
    setSubActiveTab:(val: (v: number) => number) => void;
  }
//   const Recommend = ({setSubActiveTab}:propType) => {
const Programs=()=>{
    const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const[programData,setprogramData]=useState([]);
  const[activeTab,setActiveTab]=useState(0);
  
  const[progType,setProgType]=useState(1)
  const[progId,setProgId]=useState()
  useEffect(()=>
  {
    fetchCoacheeProgram(cookie,progType)
    .then((result: any) => {
    console.log("program data is:",result.data)
    setprogramData(result.data.history)

    
    })
    .catch((error: any) => {
      console.error("Error fetching Program data:", error);
      notifyError(error);
    });
}, [cookie,progType]);


const handleShow=(uId:any)=>{
  setActiveTab(1)
  setProgId(uId)


  
}
    return(<>
      {activeTab==0 && (<>
    
    <div className={styles.dashcontainer}>
      <div className={styles.UpcomingContainer}>
      <div className={styles.UpcomingPList}>
        <p onClick={()=>setProgType(1)} className={`${styles.tab1} ${progType === 1 && styles.setliactive}`}>Live Program</p>
        <p onClick={()=>setProgType(2)} className={`${progType === 2 && styles.setliactive}`}>Recorded Program</p>
        <p onClick={()=>setProgType(3)} className={`${progType === 3 && styles.setliactive}`}>Enrolled Program</p>
          
          </div>
          
          </div>
          <div className={styles.DashForRecom}>
          <div className={styles.Recommond}>
        
       {programData.length>0?(programData.map((pdata:any)=><>
       
      
        <div className={styles.coaching}>
        <img
                      src={`https://dxe2g9i1k0e2c.cloudfront.net/${pdata.programImg}`}
                      className={styles.coachingImg}
                    />
            <div className={styles.coachingTxt} onClick={() => handleShow(pdata._id)}>
              <p>{pdata.name}</p>
              <div className={styles.Description}>
               
               <p> {pdata.description.slice(0,40)}..</p>
              </div>
              <div className={styles.ProgramDate}>
                <p><CheckCircleIcon/> {pdata.createdAt.slice(0,10)}</p>
              </div>
            </div>
            </div>
        


       </>)):(<>No coach Data Found!</>)}
      </div>
      </div>
     </div>
        

  </>)} {activeTab===1 && progType===2 && ( <ShowProgram progId={progId}/>)}
  {activeTab ===1 &&  progType===1 && (<LiveProgram progId={progId}/>)}

    </>)
}
export default Programs;


