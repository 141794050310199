// Notify.js
import { toast } from "react-toastify";

const notifySuccess = (message: any) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 8000,
  });
};

const notifyError = (message: any) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 8000,
  });
};

export { notifySuccess, notifyError };
