import { TextField } from "@mui/material";
import style from "../../../../styles/setting.module.css";
import { blue } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
const SetTab3 = () => {
  return (
    <>
      <div className={style.tabcontainer}>
        <header className={style.tabheader}>Notification</header>
        <div className={style.notification}>
          <p>
            Lorem Ipsum is simply dummy text of the printing and type setting
            industry. Lorem Ipsum has been the industry's standards
          </p>
          <div className={style.NotifyType}>
            <h6>Type</h6>
            <h6>Email</h6>
          </div>
          <div className={style.NotifyContainer}>
            <div className={style.NotifyData}>
              <p>Inbox Messages </p>
              <p>Lesson Question</p>
              <p>Session Reminder</p>
            </div>
            <div className={style.NotifyData1}>
              <Checkbox
                defaultChecked
                sx={{
                  color: "#0D386E",
                  "&.Mui-checked": {
                    color: "#0D386E",
                  },
                }}
              />
              <Checkbox
                sx={{
                  color: "#0D386E",
                  "&.Mui-checked": {
                    color: "#0D386E",
                  },
                }}
              />
              <Checkbox
                sx={{
                  color: "#0D386E",
                  "&.Mui-checked": {
                    color: "#0D386E",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className={style.formbutton}>
          <button>Update</button>
        </div>
      </div>
    </>
  );
};

export default SetTab3;
