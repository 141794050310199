import { TextField } from "@mui/material";
import styles from "./setup.module.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { coachexp } from "../../Api";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const CpTab3 = ({ setActiveTab }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  console.log("cpTab3 ", user);
  const cookie: any = user?.token;
  const [yearExp, setyearExp] = useState(0);
  const [style, setstyle] = useState("");
  const [model, setmodel] = useState("");
  const [perfect, setperfect] = useState("");
  const [challen, setchallenges] = useState("");
  const [currentP, setcurrentprofession] = useState("");
  const [arrayValues, setArrayValues] = useState<string[]>([]);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const challenges: any = challen
      .split(",")
      .map((value: any) => value.trim());
    setArrayValues(challenges);
    const currentprofession: any = currentP
      .split(",")
      .map((value: any) => value.trim());
    setArrayValues(currentprofession);
    console.log(yearExp, style, model, perfect, challenges, currentprofession);
    coachexp(
      yearExp,
      style,
      model,
      perfect,
      challenges,
      currentprofession,
      cookie
    )
      .then((result: any) => {
        console.log(result);
        setActiveTab((prev) => prev + 1);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className={styles.mainContainer}>
        <h3>Coaching Experience </h3>
        <br />
        <form onSubmit={handleSubmit}>
          <div className={styles.inputContent}>
            <div className={styles.LeftInputField}>
              <TextField
                type="number"
                id="outlined-basic"
                label="Years of Experience"
                variant="outlined"
                onChange={(e: any) => setyearExp(e.target.value)}
                value={yearExp}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="Methodology or model used when coaching"
                variant="outlined"
                onChange={(e) => setmodel(e.target.value)}
                value={model}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="Challenges/adversities faced as a coach"
                variant="outlined"
                onChange={(e) => setchallenges(e.target.value)}
                value={challen}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
            </div>
            <div className={styles.RightInputField}>
              <TextField
                type="text"
                id="outlined-basic"
                label="Coaching style"
                variant="outlined"
                onChange={(e) => setstyle(e.target.value)}
                value={style}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="Perfect Client"
                variant="outlined"
                onChange={(e) => setperfect(e.target.value)}
                value={perfect}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="Way stay current in coaching as a profession"
                variant="outlined"
                onChange={(e) => setcurrentprofession(e.target.value)}
                value={currentP}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
            </div>
          </div>
          <div className={styles.textcenter1}>
            <button className={styles.button4} type="submit">
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CpTab3;
