import { TextField } from "@mui/material";
import styles from "../setup.module.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { CoacheePersonalInfo } from "../../../Api";
import { setProfileSetup } from "../../../redux/userSlice";
import { UserType } from "../../../@types/user";

type propType = {
  setCActiveTab: (val: (v: number) => number) => void;
};
interface NewUser {
  firstName: string;
  lastName: string;
  mono: string;
  address: string;
  city: string;
  state: string;
  profile: File | null;
}

const CoacheeTab1 = ({ setCActiveTab }: propType) => {
  const [arrayValues, setArrayValues] = useState<string[]>([]);
  const user = useSelector((state: RootState) => state.user.user);
  const [previewImage, setPreviewImage] = useState("");
  const [newUser, setNewUser] = useState<NewUser>({
    firstName: "",
    lastName: "",
    mono: "",
    address: "",
    city: "",
    state: "",
    profile: null,
  });
  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, profile: file });
    console.log("files", file);

    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const cookie: any = user?.token;
  const dispatch = useDispatch();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    var formData: any = new FormData();
    formData.append("firstName", newUser.firstName);
    formData.append("lastName", newUser.lastName);
    formData.append("mono", newUser.mono);
    formData.append("location", JSON.stringify({streetName:newUser.address,city:newUser.city,state:newUser.state}));
    formData.append("profile", newUser.profile);

    CoacheePersonalInfo(formData, cookie)
      .then((result: any) => {
        console.log(result);

        let x: UserType = {
          ...result.data.user,
          token: result.data.token,
        };
      
        setCActiveTab((prev) => prev + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className={styles.FormContainer}>
        <h3>Personal Info</h3>
        <form onSubmit={handleSubmit}>
          <div className={styles.InputContainer}>
            <TextField
              type="text"
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              name="firstName"
              value={newUser.firstName}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              name="lastName"
              value={newUser.lastName}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
          </div>
          <div className={styles.CoacheeFormContent}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Mono"
              variant="outlined"
              name="mono"
              value={newUser.mono}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="Address"
              variant="outlined"
              name="address"
              value={newUser.address}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 2,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
          </div>
          <div className={styles.InputContainer}>
            <TextField
              type="text"
              id="outlined-basic"
              label="City "
              variant="outlined"
              name="city"
              value={newUser.city}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="State"
              name="state"
              variant="outlined"
              value={newUser.state}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
          </div>
          <div className={styles.ProfileImage}>
            <div className={styles.ProfileImage}>
              <input
                type="file"
                id="fileInput"
                name="profile"
                onChange={handleProfile}
                accept="image/*"
                style={{ display: "none" }}
              />
              <div className={styles.MainImg}>
                {!previewImage ? (
                  <>
                    <img
                      src="/images/Coach/NonePorfile.jpg"
                      alt="Preview"
                      className={styles.MainImgPre}
                    />

                    <div className={styles.IconImg}>
                      <img
                        src="/images/svg/Coach/group-8291.svg"
                        onClick={handleProfileClick}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={previewImage}
                      alt="Preview"
                      className={styles.MainImgPre}
                    />
                    <div className={styles.IconImg}>
                      <img
                        src="/images/svg/Coach/group-8291.svg"
                        onClick={handleProfileClick}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={styles.textcenter1}>
            <button className={styles.button4} type="submit">
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CoacheeTab1;
