import { TextField } from "@mui/material";
import style from "../../../../styles/setting.module.css";
const SetTab7 = () => {
  return (
    <>
      <div className={style.tabcontainer}>
        <div className={style.tabheader}>Add Payment Detail</div>
        <div className={style.tabwithdraw}>
          <b>Select Option To Withdraw</b>
          <div className={style.withdrawcontainer}>
            <div className={style.Withdrawcheck}>
              <input type="checkbox" className={style.YesNo} />
              <img src="./images/Coach/PayPal-Logo.png" />
            </div>
            <div className={style.Withdrawcheck1}>
              <input type="checkbox" className={style.YesNo} />
              <img src="./images/Coach/VenmoLogo.png" />
            </div>
          </div>
          <div className={style.inputtab7}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Specialty Experience/Sweet Spot"
              variant="outlined"
              sx={{ mr: 1, my: 1 }}
              className={style.input7tab}
              InputProps={{
                className: `${style.muiInput}`,
              }}
            />
          </div>
        </div>
        <div className={style.formbutton}>
          <button type="submit">Save</button>
        </div>
      </div>
    </>
  );
};

export default SetTab7;
