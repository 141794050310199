import React from "react";
import style from "./wrapper/landing.module.css";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const TermsCondi = ({ setActiveTab }: propType) => {
  return (
    <div className={style.TermsCondiContent}>
      <div className={style.TermsCondi}>
        <h4>Terms & Conditions</h4>
        <p>
          {" "}
          Lorem Ipsum is simply dummy text of the printing and type setting
          industry. has been the industry standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to on people goes under takern goes on that can help people had make a
          type specimen book got all kind people attention under when an unknown
          printer took a galley of type and scrambled it to on people goes until
          known finds on that can lorem ipsom galley of type and scrambled sout
          got text of the printing and type setting industry has been the
          industry standard dummy text ever since the Lorem Ipsum is simply
          dummy text of the printing and type setting industry. has been the
          industry standard dummy text ever since the 1500s, when an unknown
          printer took a galley of type and scrambled it to on people goes under
          takern goes on that can help people had make a type an unknown printer
          took a galley of type and scrambled it to on people goes under takern
          goes on that can help people had printer took a galley of type and
          scrambled it to on people goes under takern goes on that can specimen
          to on people goes under takern goes on that book has been the industry
          standard dummy text ever since the Lorem Ipsum is simply dummy text of
          the printing and type setting industry. has been the industry standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to on people goes under takern goes on that
          can help people had make a type an unknown printer took a galley.
          Lorem Ipsum is simply dummy text of the printing and type setting
          industry. has been the industry standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to on people goes under takern goes on that can help people had make a
          type specimen book got all kind people attention under when an unknown
          printer took a galley of type and scrambled it to on people goes until
          known finds on that can lorem ipsom galley of type and scrambled sout
          got text of the printing and type setting industry has been the Lorem
          Ipsum is simply dummy text of the printing and type setting industry.
          has been the industry standard dummy text ever since the 1500s, when
          an unknown printer took a galley of type and scrambled it to on people
          goes under takern goes on that can help people had make a type
          specimen book got all kind people attention under when an unknown
          printer took a galley of type and scrambled it to on people goes until
          known finds on that can lorem ipsom galley of type and scrambled sout
          got text of the printing and type setting industry has been the
          industry standard Lorem Ipsum is simply dummy text of the printing and
          type setting industry. has been the industry standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to on people goes under takern goes on that can help
          people had make a type specimen book got all kind people attention
          under when an unknown printer took a galley of type and scrambled it
          to on people goes until known finds on that can lorem ipsom galley of
          type and scrambled sout got text of the printing and type setting
          industry has been the industry standard dummy text ever since Lorem
          Ipsum is simply dummy text of the printing and type setting industry.
          has been the industry standard dummy text ever since the 1500s, when
          an unknown printer took a galley of type and scrambled it to on people
          goes under takern goes on that can help people had make a type
          specimen book got all kind people attention under when an unknown
          printer took a galley of type and scrambled it to on people goes until
          known finds on that can lorem ipsom galley of type and scrambled sout
          got text of the printing and type setting industry has been the
          industry standard dummy. Lorem Ipsum is simply dummy text of the
          printing and type setting industry. has been the industry standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to on people goes under takern goes on that
          can help people had make a type specimen book got all kind people
          attention under when an unknown printer took a galley of type and
          scrambled it to on people goes until known finds on that can lorem
          ipsom galley of type and scrambled sout got text of the printing and
          type setting industry has been the industry standard dummy text ever
          since Lorem Ipsum is simply dummy text of the printing and type
          setting industry. has been the industry standard dummy text ever since
          the 1500s, when an unknown printer took a galley of type and scrambled
          it to on people goes under takern goes on that can help people had
          make a type specimen book got all kind people attention under when an
          unknown printer took a galley of type and scrambled it to on people
          goes until known finds on that can lorem ipsom galley of type and
          scrambled sout got text of the printing and type setting industry has
          been the industry standar. Lorem Ipsum is simply dummy text of the
          printing and type setting industry. has been the industry standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to on people goes under takern goes on that
          can help people had make a type specimen book got all kind people
          attention under when an unknown printer took a galley of type and
          scrambled it to on people goes until known finds on that can lorem
          ipsom galley of type and scrambled sout got text of the printing and
          type setting industry has been the industry Lorem Ipsum is simply
          dummy text of the printing and type setting industry. has been the
          industry standard dummy text ever since the 1500s, when an unknown
          printer took a galley of type and scrambled it to on people goes under
          takern goes on that can help people had make a type specimen book got
          all kind people attention under when an unknown printer took a galley
          of type and scrambled it to on people goes until known finds on that
          can lorem ipsom galley of type and scrambled sout got text of the
          printing and type setting industry has been the industry standard
          dummy text ever since Lorem Ipsum is simply dummy that can until hed
          people got also Lorem Ipsum is simply dummy text of the printing and
          type setting industry. has been the industry standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to on people goes under takern goes on that can help
          people had make a type specimen book got all kind people attention
          under when an unknown printer took a galley of type and scrambled it
          to on people goes until known finds on that can lorem ipsom galley of
          type and scrambled sout got text of the printing and type setting
          industry has been the industry standard dummy. Lorem Ipsum is simply
          dummy text of the printing and type setting industry. has been the
          industry standard dummy text ever since the 1500s, when an unknown
          printer took a galley of type and scrambled it to on people goes under
          takern goes on that can help people had make a type specimen book got
          all kind people attention under when an unknown printer took a galley
          of type and scrambled it to on people goes until known finds on that
          can lorem ipsom galley of type and scrambled sout got text of the
          printing and type setting industry has been the industry Lorem Ipsum
          is simply dummy text of the printing and type setting industry. has
          been the industry standard dummy text ever since the 1500s, when an
          unknown printer took a galley of type and scrambled it to on people
          goes under takern goes on that can help people had make a type
          specimen book got all kind people attention under when an unknown
          printer took a galley of type and scrambled it to on people goes until
          known finds on that can lorem ipsom galley of type and scrambled sout
          got text of the printing Lorem Ipsum is simply dummy text of the
          printing and type setting industry. has been the industry standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to on people goes under takern goes on that
          can help people had make a type specimen book got all kind people
          attention under when an unknown printer took a galley of type and
          scrambled
        </p>
      </div>
    </div>
  );
};

export default TermsCondi;
