import { useLocation, useNavigate } from "react-router-dom";
import styles from "./forgot.module.css";
import { ForgetPassEmail } from "../../Api";
import { useState } from "react";
import { UserType } from "../../@types/user";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userSlice";

type propType = {
  setMailsubmitted: (val: boolean) => void;
};
const Main = ({ setMailsubmitted }: propType) => {
  const dispatch = useDispatch();
  const [userinput, setUserInput] = useState("");
  const handleNext = (e: any) => {
    e.preventDefault();
    ForgetPassEmail(userinput)
      .then((result: any) => {
        let x: UserType = { ...result.data.user, id: result.data.id };
        dispatch(login(x));
        sessionStorage.setItem("user", JSON.stringify(x));
        console.log("forgetPass Data ", x);
        if (result) {
          setMailsubmitted(true);
        }
      })

      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
        setMailsubmitted(false);
      });
  };

  return (
    <div>
      <form onSubmit={handleNext}>
        <div>
          <label className={styles.mailText}> Email Address</label>
          <input
            className={styles.InputBox}
            type="email"
            name="userinput"
            value={userinput}
            onChange={(e: any) => setUserInput(e.target.value)}
          />
        </div>
        <div>
          <button className={styles.btn} type="submit">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Main;
