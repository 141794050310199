import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import styles from "../programs/programs.module.css";
import {  DeleteGroup, fetchAgroup, fetchCoach } from "../../../../Api";
type propType = {
  setActivescreen: (val: (v: number) => number) => void;
  upd:any;
  setPid:any;
};
const DispGroup = ({ setActivescreen,upd,setPid }: propType) => {

  const [assignedCoach, setassignedCoach] = useState<any[]>([]);
  const [groupData1, setGroupdata] = useState<any>([]);
  const user = useSelector((state: RootState) => state.user.user);
  const [update, setUpdate] = useState(false);
 
  const cookie: any = user?.token;
  const handlePid = (groupId:any) => {
    setActivescreen(()=>3);
    setPid(groupId);
  };
 const DeleteAGroup=(gid:any,e: React.MouseEvent<HTMLAnchorElement, MouseEvent>)=>{
  e.preventDefault();

 DeleteGroup(gid,cookie)
 .then((result: any) => {
  console.log(result);
  setUpdate(prev => !prev)
  
 
})

.catch((error) => {
  console.log(error);
});
 }
 useEffect(() => {
  fetchAgroup(cookie)
    .then((result: any) => {
      setGroupdata(result.data);
      
    })
    .catch((error: any) => {
      console.error("Error fetching coach data:", error);
    });
}, [cookie,update,upd]);
  

  // alert(groupData);
  const handleAddCoach = (coachId: string) => {
    if (!assignedCoach.includes(coachId)) {
      setassignedCoach([...assignedCoach, coachId]);
    }
  };

  return (
    <div>
      {" "}
      <table>
        <tr>
          <th> GROUP TOPIC </th>
          <th> ASSIGNED COACH </th>
          <th>TOTAL SESSIONS</th>
          <th>SESSION COMPLETED</th>
          <th>DATE</th>
          <th>TOTAL USERS</th>
          <th > delete </th>
        </tr> 

        {groupData1.length > 0 ? (
          groupData1.map((group: any, index: number) => (
            <>
              <tr>
              
                <td className={styles.groupInfo1}><span className={styles.groupIc}>{group.groupTopic.slice(0,2)}</span>
               <span> {group.groupTopic}</span></td>
               <td className={styles.assignedCoach}>
                {group.coachIds.map((coach:any)=>(<>{coach.profileImg?(<>
                  <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                    className={styles.groupIc1}/></>):(<><img src="/images/svg/Coach/setting/personal.svg" className={styles.groupIc1} /></>)}   {coach.name} </>))}
               </td> 

<td>Total Session</td>
<td>complete Session</td>
               
                <td>{group.createdAt.substring(0,10)}</td>
                <td>{group.coacheeIds.length}
</td>
<td className={styles.editIcon}>
<img src="/images/svg/Admin/viewIcon.svg" onClick={()=>handlePid(group._id)}/>
                      <img src="/images/svg/Admin/EditIcon.svg" />
                      <img src="/images/svg/Admin/deletIcon.svg"  onClick={(e:any) => DeleteAGroup(group._id,e)} />
</td>
              </tr>
            </>
          ))
        ) : (
          <tr>
            <td>No coach data available</td>
            
          </tr>
        
        )}
      
      </table>
    </div>
  );
};

export default DispGroup;
