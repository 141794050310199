// import BkLayer1 from "../../../svg/bk-layer";
import Style from "./index.module.css";

type propType = {
  heading?: string;
  message?: string;
  children?: any;
};
const AuthWrapper = ({ heading, message, children }: propType) => {
  return (
    <div className={Style.container}>
      <div className={Style.bkTop}>
        <img src="/images/svg/Coach/shapebk.svg" className={Style.bkTopInner} />
      </div>

      <div className={Style.mainContainer}>
        <h2 className={Style.heading}>{heading}</h2>
        <p className={Style.message}>{message}</p>
        <div className={Style.mainContainerInner}>{children}</div>
      </div>

      <img src="/images/Coach/person-jump.png" className={Style.personJump} />
      <footer className={Style.footer}>
        Copyright 2022 © DreamSmart Behavioural Solutions
      </footer>
    </div>
  );
};

export default AuthWrapper;
