import style from "./wrapper/landing.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
 

};
const AboutUs = ({ setActiveTab }: propType) => {
  return (
    <>
      <div className={style.HomeContainer}>
      <div className={style.headerContainer}>
        
        <header className={style.headerTxt}>
          <h1>
            lorem ipsom is simple dummy{" "}
            <span className={style.DSHead}>Dream Smart Solution</span> text
            that can used on that can on people goes
          </h1>
        </header>
      </div>
       <div className={style.headerpara}>
          <p>
            Lorem Ipsum is simply dummy text of the printing and type setting
            industry. Lorem Ipsum has been the industry standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and
            scrambled it to on people goes under takern goes on that can help
            people had make a type specimen book.
          </p>
        </div>
       
       
        <div className={style.HeaderImg}>
          <img src="/images/landing/Laptop2.png"  className={style.HeaderImgIc}/>

          {/* <div className={style.TotSimpleSoft}>
            {" "}
            <span>
              <h2 className={style.WhatInnerp}>13</h2>
              <p>Years of Experties</p>
            </span>
            <span>
              <h2 className={style.WhatInnerp}>+45k</h2>
              <p>Satisfied Global Users</p>
            </span>
            <span>
              <h2 className={style.WhatInnerp}>+185k</h2>
              <p>Sessions Completed</p>
            </span>
            <span>
              <h2 className={style.WhatInnerp}>4.6</h2>
              <p>Satisfied Feedbacks</p>
            </span>
          </div> */}
        </div>
        <div className={style.ABSimpleSoft}>
          <div className={style.SimpleSoft}>
            <div className={style.SimpleSoftContent}>
              <div className={style.SimpleSoftLeft}>
                <h2>Why Choose Dream Smart Solution</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and type
                  setting industry. has been the industry standard dummy text
                  ever since the
                </p>
              </div>
              <div className={style.ABSimpleSoftRight}>
                <p>
                  {" "}
                  <CheckCircleIcon className={style.CheckCircle} />
                  <br />
                  <b>Choose Your Coaches </b>
                  <br />
                  Lorem Ipsum is simply dummy text of the printing and type
                  setting industry. has been the industry standard dummy
                </p>
                <p>
                  {" "}
                  <CheckCircleIcon className={style.CheckCircle} />
                  <br />
                  <b>Easy To Book Session</b>
                  <br />
                  Lorem Ipsum is simply dummy text of the printing and type
                  setting industry. has been the industry standard dummy
                </p>
                <p>
                  {" "}
                  <CheckCircleIcon className={style.CheckCircle} />
                  <br />
                  <b>Choose Your Coaches </b>
                  <br />
                  Lorem Ipsum is simply dummy text of the printing and type
                  setting industry. has been the industry standard dummy
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.OurMsVs}>
          <div className={style.UpComingSect}>
          <div className={style.WhatFeatureContent}>
          <div className={style.WFDiv}>
                  <span className={style.WFSpan}>Our Mission</span>
                  <h4>
                    <b>
                      Loram ipsom is simple dummy text that can goes people on
                    </b>
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and type
                    setting industry. has been the industry standard dummy text
                    ever since the 1500s, when an unknown printer took a galley
                    of type and scrambled it to on people goes under takern goes
                    on that can help people had make a type an unknown printer
                    took a galley of type and scrambled it to on people goes
                    under takern goes on that can help people had printer took a
                    galley of type and scrambled it to on people goes under
                    takern goes on that can specimen to on people goes under
                    takern goes on that book.
                  </p>
                </div>
                <div className={style.WFDiv}>
                  <img src="/images/svg/Admin/OurMiss.svg" />
                </div>
          </div>
          <div className={style.WhatFeatureContent}>
          <div className={style.WFDiv}>
                  <img src="/images/svg/Admin/OurVisio.svg" />
                </div>
                <div className={style.WFDiv}>
                  <span className={style.WFSpan}>Our Vision</span>
                  <h4>
                    <b>
                      Loram ipsom is simple dummy text that can goes people on
                    </b>
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and type
                    setting industry. has been the industry standard dummy text
                    ever since the 1500s, when an unknown printer took a galley
                    of type and scrambled it to on people goes under takern goes
                    on that can help people had make a type an unknown printer
                    took a galley of type and scrambled it to on people goes
                    under takern goes on that can help people had printer took a
                    galley of type and scrambled it to on people goes under
                    takern goes on that can specimen to on people goes under
                    takern goes on that book.
                  </p>
                </div>
                </div>
                <div className={style.WhatFeatureContent}>
                <div className={style.WFDiv}>
                  <span className={style.WFSpan}>Our Values</span>
                  <h4>
                    <b>
                      Loram ipsom is simple dummy text that can goes people on
                    </b>
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and type
                    setting industry. has been the industry standard dummy text
                    ever since the 1500s, when an unknown printer took a galley
                    of type and scrambled it to on people goes under takern goes
                    on that can help people had make a type an unknown printer
                    took a galley of type and scrambled it to on people goes
                    under takern goes on that can help people had printer took a
                    galley of type and scrambled it to on people goes under
                    takern goes on that can specimen to on people goes under
                    takern goes on that book.
                  </p>
                </div>
                <div className={style.WFDiv}>
                  <img src="/images/svg/Admin/OurVal.svg" />
                </div>
                </div>
                <div className={style.WhatFeatureContent}>
                <div className={style.WFDiv}>
                  <h4>
                    <br />
                    <br />
                    <br />
                    <b>
                      Lorem ipsom is simple dummy text that can people can goat
                      on that
                    </b>
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and type
                    setting industry has been the industry standard dummy text
                    of the that to printing and type setting industry text ever
                    since simply dummy text of the printing and type setting
                    industry has been the industry standard dummy text of the
                    that ipsom is simple dummy text that under goes that until
                    goes open
                  </p>
                  </div>
                  <div className={style.WFDiv}>
                  <img src="/images/landing/ZoomMeet.png" />
                </div>
                </div>
                <div className={style.WhatFeatureContent}>
                <div className={style.WFDiv}>
                  <h4>
                    <br />
                    <br />
                    <br />
                    <b>
                      Thousands of customers love us see what they say about us
                    </b>
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and type
                    setting industry. has been the industry that standard dummy
                    text ever since the people can hand on experience that ipsom
                    also text of the printing and type text ever since the
                    people can hand on
                  </p>
                </div>
                </div>


</div>
</div>
      </div>
    </>
  );
};

export default AboutUs;
