import style from "./showPro.module.css"

const ShowRecordProgram=()=>{
    return(<>
    <div className={style.showRecord}>
        <div className={style.showRecordContent}>
        <div className={style.showRecordVideo}>

        </div>
        <br/>
        <h6>Name of Program</h6>
        <p> Description of Program<br/> files Are attacted with the program</p>
        <p>duration</p>
            
        </div>
        <div className={style.showRecordList}>
            <table>
                <tr>
                <th>Program Name</th>
                <th>Program Description</th>
                <th>Duration</th>
                <th>Progress</th>
                </tr>
                <tr>
                    <td>Introduction of Program</td>
                    <td> Description of Program</td>
                    <td> 3 Hrs</td>
                    <td>Incomplete </td>
                </tr>
                <tr>
                    <td>Introduction of Program</td>
                    <td> Description of Program</td>
                    <td> 1 Hrs</td>
                    <td>Incomplete </td>
                </tr>
            </table>
        </div>
    </div>
    </>)
}
export default ShowRecordProgram;