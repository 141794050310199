import React from "react";
import { Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const MyCertificate=()=> {
  return (
    <>
    <div className={styles.dashcontainer}>
        <div className={styles.UpcomingContainer}>
          <div className={styles.Upcoming}>
            <div className={styles.UpcomingP}>
              <p>My Certificates</p>
            </div>
          </div>
          <div className={styles.Recommond}>
          <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
              <div className={styles.coachingTxt}>
                <p>Hoello</p>
                <div className={styles.category}>
                 
                 <span>This is text</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>email ID</span>
                </div>
              </div>
              </div>
          </div>
  
          </div>
          </div>
          
    </>
  )
}

export default MyCertificate