import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResetOtp, VerifyApi } from "../../Api";
import styles from "./otp.module.css";

import { RootState } from "../../redux/store";
import { UserType } from "../../@types/user";
import { login } from "../../redux/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import { notifyError, notifySuccess } from "../../Notify";
type propType = {
  setVefified: (val: boolean) => void;
  type: string;
};

const Verify = ({ setVefified, type }: propType) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user: any = useSelector((state: RootState) => state.user.user);
  const [otp, setOtp] = useState<Array<number | string>>(["", "", "", ""]);
  const [concatOtp, setconcatOtp] = useState<string>("");
  const [tabActivated, setTabActivated] = useState(false);
  const [reqtype, setReqtype] = useState("");
  const inputRefs: any = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  
  useEffect(() => {
    if (location.pathname === "/verify") {
      setReqtype("regi");
    }
    if (location.pathname === "/forget-password") {
      setReqtype("forget");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (otp.every((value) => value !== "")) {
      setTabActivated(true);
    }
  }, [otp]);
  console.log(user?.profileSetup);

  const handleChange = (index: number, value: string) => {
    const newInputValues = [...otp];
    newInputValues[index] = value;
    setOtp(newInputValues);
    if (value !== "" && index < 3) {
      inputRefs[index + 1].current.focus();
    }
  };
  console.log(user?.profileSetup);
  const  id: any = user?._id || user?.id;
  const concatValue: any = otp.join("");

  const handleVerify = async (e: React.FormEvent) => {
    e.preventDefault();
    if (location.pathname === "/verify") {
      setconcatOtp(concatValue);
      VerifyApi(concatValue, id, reqtype)
        .then((result: any) => {
          const {token,usr}=result.data;
          let x: UserType = {
            ...usr,
            token:token,
            account_status: usr.account_status,
            role: usr.role,
            profileSetup: usr.profileSetup,
          };
          dispatch(login(x));
          navigate("/setup");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (location.pathname === "/forget-password") {
      console.log("OTP verifyData is :", concatValue, id, reqtype);
      setconcatOtp(concatValue);
      VerifyApi(concatValue, id, reqtype)
        .then((result: any) => {
          let x: UserType = {
            ...result.data.user,
            token: result.data.token,
            account_status: result.data.account_status,
            role: result.data.role,
            profileSetup: result.data.profileSetup,
          };
          // alert(result.response);

          if (result) {
            dispatch(login(x));

            setVefified(true);
          }
        })
        .catch((error: any) => {
          notifyError(error.response.data.message);
          console.log(error);
          setVefified(false);
        });
    }
  };

  const types = "1";
  const resendOTP = (e: React.FormEvent) => {
    e.preventDefault();
    let refId= id;
    ResetOtp(refId, types).then(()=>notifySuccess("OTP Re-Send Sucessfully!"))
    .catch(()=>notifyError("Failed to send OTP"))
  };
  const handleReset = () => {};
  return (
    <>
      {" "}
      <form onSubmit={handleVerify}>
        <div className={styles.container}>
          {otp.map((value, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={value}
              onChange={(e) => handleChange(index, e.target.value)}
              ref={inputRefs[index]}
            />
          ))}
        </div>

        <center>
          <span className={styles.code}>
            Did't receive any code?{" "}
            <button onClick={resendOTP}>
              Request new code
            </button>
          </span>
        </center>
        <center>
          <button
            className={styles.createid}
            type="submit"
            onClick={handleReset}
          >
            Verify &{type === "forget" ? "Reset Password" : "Create an account"}
          </button>
        </center>
      </form>
    </>
  );
};

export default Verify;
