import {
  InputAdornment,
  TextField,
} from "@mui/material";
import FormData from "form-data";
import styles from "./setup.module.css";
import { LocationOn } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import PersonIcon from '@mui/icons-material/Person';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import ReactPlayer from "react-player";
import { ContactInfo } from "../../Api";
import dayjs from "dayjs";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
interface NewUser {
  bdate:string;
  phoneNo: string;
  behaviourIdenty: string;
  topvalues: string;
  comvalues: string;
  locationvalue: string;
  linkedinLink: string;
  instagramLink: string;
  facebookLink: string;
  twitterLink: string;
  profile: File | null;
  videoLink: File | null;
}
const CpTab1 = ({ setActiveTab }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const [newUser, setNewUser] = useState<NewUser>({
    bdate:"",
    phoneNo: "",
    behaviourIdenty: "",
    topvalues: "",
    comvalues: "",
    locationvalue: "",
    linkedinLink: "",
    instagramLink: "",
    facebookLink: "",
    twitterLink: "",
    profile: null,
    videoLink: null,
  });
  // profile

  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleVideoClick = () => {
    const videoInput = document.getElementById("videoInput");
    if (videoInput) {
      videoInput.click();
    }
  };
  const handleVideo = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setNewUser({ ...newUser, videoLink: file });
      console.log("video Files", file);

      const videoURL = URL.createObjectURL(file);
      setPreviewVideo(videoURL);
    
    }
  };
  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, profile: file });
    console.log("files", file);

    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const cookie: any = user?.token;

  const [previewImage, setPreviewImage] = useState("");
  const [previewVideo, setPreviewVideo] = useState("");


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    var formData = new FormData();

    const behavioralIdentity: string[ ] = newUser.behaviourIdenty.split(",");
    const topTalents: string[]= newUser.topvalues.split(",");
    const commpower: string[] = newUser.comvalues.split(",");
    const location: any = JSON.stringify(newUser.locationvalue);


    formData.append("birthDate", dayjs(newUser.bdate).format("DD-MM-YYYY"));
    formData.append("phoneNo", newUser.phoneNo);
    formData.append("behavioralIdentity[0]", behavioralIdentity);
    formData.append("topTalents", topTalents);
    formData.append("commpower", commpower);
    formData.append("location", location);
    formData.append("linkedinLink", newUser.linkedinLink);
    formData.append("instagramLink", newUser.instagramLink);
    formData.append("facebookLink", newUser.facebookLink);
    formData.append("twitterLink", newUser.twitterLink);
    formData.append("profile", newUser.profile);
    formData.append("videoLink", newUser.videoLink);
    console.log("ffData", formData);

    ContactInfo(formData, cookie)
      .then((result: any) => {
        console.log(result);
        setActiveTab((prev) => prev + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={styles.mainContainer}>
      <h3>Personal Info</h3>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputContent}>
          <div className={styles.LeftInputField}>
            <p className={styles.DateOf}>Date of Birth</p>
            <TextField
              type="date"
              id="outlined-basic"
              // label="Date Of Birth"
              variant="outlined"
              name="bdate"
          
              value={newUser.bdate}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Behavioral Superpowers Identity"
              variant="outlined"
              name="behaviourIdenty"
              value={newUser.behaviourIdenty}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="communication SuperPower"
              variant="outlined"
              name="comvalues"
              className={styles.Inputfield}
              value={newUser.comvalues}
              onChange={handleChange}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="LinkedIn Profile link"
              variant="outlined"
              name="linkedinLink"
              value={newUser.linkedinLink}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="facebook Profile link"
              variant="outlined"
              name="facebookLink"
              value={newUser.facebookLink}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
          </div>
          <div className={styles.RightInputField}>
          <TextField
              type="number"
              id="outlined-basic"
              label="Contact Number"
              variant="outlined"
              name="phoneNo"
              value={newUser.phoneNo}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="Three Top Talents"
              variant="outlined"
              name="topvalues"
              value={newUser.topvalues}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />

            <TextField
              id="outlined-basic"
              label="Location (i.e.,streetname,country,state,pincode)"
              variant="outlined"
              name="locationvalue"
              value={newUser.locationvalue}
              onChange={handleChange}
              sx={{
                mr: 2,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              InputProps={{
                className: `${styles.muiInput}`,
                endAdornment: (
                  <InputAdornment position="end">
                    <LocationOn />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="instagram Profile link"
              variant="outlined"
              name="instagramLink"
              value={newUser.instagramLink}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="Twitter Profile link"
              variant="outlined"
              name="twitterLink"
              value={newUser.twitterLink}
              onChange={handleChange}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            />
          </div>
        </div>
        <div className={styles.ImgVideoContent}>
    
          <div className={styles.ProfileImage}>
          <p>Profile Image</p>
       
            <input
              type="file"
              id="fileInput"
              name="profile"
              onChange={handleProfile}
              accept="image/*"
              style={{ display: "none" }}
            />
              
            <div className={styles.MainImg}>
              
              {!previewImage ? (
                <>
                  <PersonIcon
                    // alt="Preview"
                    className={styles.MainImgPre}
                  />

                  <div className={styles.IconImg}>
                  
                    <img
                      src="/images/svg/Coach/group-8291.svg"
                      onClick={handleProfileClick}
                    />
                  </div>
                </>
              ) : (
                <>

                  <img
                    src={previewImage}
                    alt="Preview"
                    className={styles.MainImgPre1}
                  />
                  <div className={styles.IconImg}>
                    <img
                      src="/images/svg/Coach/group-8291.svg"
                      onClick={handleProfileClick}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

                  {/*        video code        */}

          <div className={styles.ProfileImage}>
      
            <input
              type="file"
              id="videoInput"
              name="videoLink"
              onChange={handleVideo}
              accept="video/*"
              style={{ display: "none" }}
            />
           
            <div className={styles.MainImg}>
           
            {!previewVideo ? (
              <>
                <p>Select your Video</p>
                <p style={{ cursor: "pointer" }}>
             
                  <div className={styles.MainVideo}>
                  <VideoCameraFrontIcon className={styles.MainVideoIcon}/>
               
                   
                    <sub>file format mp4, mkv</sub>
                  </div>
                  <div className={styles.IconImg}>
                    <img
                      src="/images/svg/Coach/group-8291.svg"
                      onClick={handleVideoClick}
                    />
                  </div>
                </p>
              </>
            ) : (
              <>
                <div className={styles.MainVideo}>
                  <video controls>
                    <ReactPlayer url={previewVideo}/>
                    {/* <source src={previewVideo} type="video/*"className={styles.MainVideoIcon} /> */}
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className={styles.IconVideo}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    onClick={handleVideoClick}
                  />
                </div>
              </>
            )}
          </div>
          </div>
        </div>
        <div className={styles.textcenter1}>
          <button className={styles.button4} type="submit">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default CpTab1;
