import { Box, Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import { getToken } from "firebase/messaging";
// import { messaging } from "../../../firebase";
import { useEffect, useState } from "react";
// import { UpdateToken,fetchCoach } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
const Home = () => {
  const user = useSelector((state: RootState) => state.user.user);
  console.log("profile", user?.profileSetup);
  const cookie: any = user?.token;
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const [coachData, setCoachData] = useState<any[]>([]);
  const [coacheData, setCoacheData] = useState<any[]>([]);
  const [notifytoken, setnotifytoken] = useState<any>("");
  // async function requrestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     const Tok = await getToken(messaging, {
  //       vapidKey:
  //         "BGrRgMNPpZSeLkJ0LPvw8GZ41-OpfjkyS49qhtNvTG-Jd0AKLAg6qN9gURNf6MeYFDlXHoM7lhdgUt5YJdUHXBY",
  //     });
  //     setnotifytoken(Tok);
  //     try {
  //       const res = await UpdateToken(cookie, Tok);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else if (permission === "denied") {
  //     alert("You denied for the notification");
  //   }
  // }
  // useEffect(() => {
  //   fetchCoach(cookie,2)
  //     .then((result: any) => {
  //       console.log("Coach data:", result);
  //       setCoachData(result.data);
  //       requrestPermission();
  //     })
  //     .catch((error: any) => {
  //       console.error("Error fetching coach data:", error);
  //     });
  // }, [cookie]);

  return (
    <>
      <div className={styles.dashcontainer}>
        <div className={styles.upgradeTap}>
          <div className={styles.upgradeTxt}>
            <h3>Upgrade to</h3>
            <h2>Comprehensive Report</h2>
            <br />
            <h4>Features</h4>
            <p>- hello this is from your dashboard</p>
            <p>- hello this is from your dashboard</p>
            <p>- hello this is from your dashboard</p>
          </div>
          <div className={styles.upgradeButton}>
            <Button onClick={handleOpen}>Upgrade Now</Button>
            <Modal
              open={open1}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleClose} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Make Payment
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={styles.typoDesc}
                >
                  <div className={styles.UpgradeDiv}>
                    <img src="./images/Coachee/placeholder.png" />
                    <div className={styles.UpgradeText}>
                      <br />
                      <h6>Upgrade to Comprehensive Report</h6>
                      <p>-Lorem Ipsum is simply dummy text of the printing.</p>
                      <p>-Lorem Ipsum is simply dummy text of the printing.</p>
                      <p>-Lorem Ipsum is simply dummy text of the printing.</p>
                    </div>
                  </div>
                  <hr />
                  <div className={styles.TotalPay}>
                    <h6>Total Payment</h6>
                    <h6> $250.00 </h6>
                  </div>
                  <br />
                  <Button
                    className={styles.TypoButton}
                    sx={{ m: 1, width: "100%" }}
                  >
                    Pay Now
                  </Button>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div className={styles.UpcomingContainer}>
          <div className={styles.Upcoming}>
            <div className={styles.UpcomingP}>
              <p>Upcoming Coaching</p>
            </div>
            <div className={styles.ViewAl}>
              <span>View All</span>
            </div>
          </div>
          <div className={styles.Recommond}>
          <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
              <div className={styles.coachingTxt}>
                <p>Coach Name</p>
                <div className={styles.category}>
                  <span>Category 1</span>
                  <span>Category 2</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>abc@gamil.com</span>
                </div>
              </div>
              </div>
              {/* another */}
              <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
             <div className={styles.coachingTxt}>
                <p>Coach Name</p>
                <div className={styles.category}>
                  <span>Category 1</span>
                  <span>Category 2</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>abc@gamil.com</span>
                </div>
              </div>
              </div>
              <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
             <div className={styles.coachingTxt}>
                <p>Coach Name</p>
                <div className={styles.category}>
                  <span>Category 1</span>
                  <span>Category 2</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>abc@gamil.com</span>
                </div>
              </div>
              </div>
              {/*  */}
              <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
             <div className={styles.coachingTxt}>
                <p>Coach Name</p>
                <div className={styles.category}>
                  <span>Category 1</span>
                  <span>Category 2</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>abc@gamil.com</span>
                </div>
              </div>
              </div>
              {/*  */}
 
          </div>
         </div>
        {/*            Recommended Coaches     */}
        <div className={styles.UpcomingContainer}>
          <div className={styles.Upcoming}>
            <div className={styles.UpcomingP}>
              <p>Recommended Coaches</p>
            </div>
            <div className={styles.ViewAl}>
              <span>View All</span>
            </div>
          </div>
          <div className={styles.Recommond}>
          <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
             <div className={styles.coachingTxt}>
                <p>Coach Name</p>
                <div className={styles.category}>
                  <span>Category 1</span>
                  <span>Category 2</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>abc@gamil.com</span>
                </div>
              </div>
              </div>
              {/* another */}
              <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
             <div className={styles.coachingTxt}>
                <p>Coach Name</p>
                <div className={styles.category}>
                  <span>Category 1</span>
                  <span>Category 2</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>abc@gamil.com</span>
                </div>
              </div>
              </div>
              <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
             <div className={styles.coachingTxt}>
                <p>Coach Name</p>
                <div className={styles.category}>
                  <span>Category 1</span>
                  <span>Category 2</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>abc@gamil.com</span>
                </div>
              </div>
              </div>
              {/*  */}
              <div className={styles.coaching}>
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
             <div className={styles.coachingTxt}>
                <p>Coach Name</p>
                <div className={styles.category}>
                  <span>Category 1</span>
                  <span>Category 2</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>abc@gamil.com</span>
                </div>
              </div>
              </div>
              {/*  */}
 
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
