import React, { useState } from "react";

import { lightGreen } from "@mui/material/colors";
import NotAuthenticatedLayout from "../../layouts/NotAuthenticatedLayout";
import AuthWrapper from "../../layouts/authWrapper";
import styles from "../setup.module.css";

import {
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setProfileSetup } from "../../../redux/userSlice";
import { RootState } from "../../../redux/store";
import { UserType } from "../../../@types/user";

type propType = {
  setCActiveTab: (val: (v: number) => number) => void;
};
const Coacheetab2 = ({ setCActiveTab }: propType) => {
  const dispatch = useDispatch();
  const [age, setAge] = React.useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(setProfileSetup(true));
  };

  return (
    <>
      <div className={styles.abc}>
        <form onSubmit={handleSubmit}>
          <div className={styles.CoachQuality}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Couch Quality You Looking For??
            </InputLabel>
            <NativeSelect
              defaultValue={10}
              inputProps={{
                name: "age",
                id: "uncontrolled-native",
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            >
              <option value={10}>Find Couch Like Me</option>
              <option value={20}>Find Couch Not Like Me</option>
              <option value={30}>Surprise Me</option>
            </NativeSelect>
            <br />
            <div className={styles.FormButton}>
              <button
                className={styles.button4}
                onClick={() => setCActiveTab((prev) => prev + 1)}
                type="submit"
              >
                Submit
              </button>
              <br />
              <button
                className={styles.FormButton1}
                onClick={() => setCActiveTab((prev) => prev - 1)}
              >
                Back
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Coacheetab2;
