import { useEffect, useState } from "react";
import GeTab1 from "./group-engagement/GE-Tab1";
import GeTab2 from "./group-engagement/GE-Tab2";
import GeTab3 from "./group-engagement/GE-Tab3";
import styles from "./group-engagement/group.module.css";
import Style from "../../../../src/styles/group.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchCoachGroup } from "../../../Api";
import { notifyError } from "../../../Notify";

interface SetupProps {}
const Group = ({}) => {
  const [activeTab, setActiveTab] = useState(1);
const[groupId,setGroupId]=useState("")
  return (
    <>
      <div className={Style.Artist}>
        {/* {Tab Container} */}
      
        
    </div>
       

        {activeTab === 1 && <GeTab1 setActiveTab={setActiveTab} setGroupId={setGroupId}/>}
        {activeTab === 2 && <GeTab2 setActiveTab={setActiveTab} groupId={groupId}/>}
        {activeTab === 3 && <GeTab3 setActiveTab={setActiveTab} />}
      
    </>
  );
};

export default Group;
