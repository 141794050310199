import React, { useState } from "react";
import Home from "../Home";
import Wrapper from "../../wrapper/Wrapper";
import Session from "../Session";
import Group from "../Group";
import Settings from "../Settings";
import Earning from "../Earning";
import Support from "../support";
import Programs from "../Programs";

const Indexing = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <Wrapper activeTab={activeTab} setActiveTab={setActiveTab}>
      {activeTab === 1 && <Home />}
      {activeTab === 2 && <Session />}
      {activeTab === 3 && <Group />}
      {activeTab === 4 && <Earning />}
      {activeTab === 5 && <Settings />}
      {activeTab === 6 && <Support />}
      {activeTab===7 && <Programs/>}
    </Wrapper>
  );
};

export default Indexing;
