import SetTab1 from "./Settings/Set-Tab1";
import SetTab2 from "./Settings/Set-Tap2";
import SetTab3 from "./Settings/Set-Tab3";
import AddCard from "./CardPayment/CheckoutForm";
import { useState } from "react";
import MainSettings from "./Settings/MainSettings";
type propType={
  subActiveTab:any
}

const Settings = (subActiveTab:any) => {
  const [activesetTab, setActivesetTab] = useState(2);
console.log(subActiveTab)
  return (
    <MainSettings activesetTab={activesetTab} setActivesetTab={setActivesetTab}>
      {activesetTab === 1 && <SetTab1 />}
      {activesetTab === 2 && <SetTab2/>}
      {/* {activesetTab === 2 && < AddCard/>} */}
      {activesetTab === 3 && <SetTab3 />}
    </MainSettings>
  );
};

export default Settings;
