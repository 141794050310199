import { TextField } from "@mui/material";
import style from "../../../../styles/setting.module.css";
const SetTab3 = () => {
  return (
    <>
      <div className={style.tabcontainer}>
        <header className={style.tabheader}>Coaching Experience</header>
        <div className={style.tabformcontent}>
          <div className={style.leftabcontent}>
            <div className={style.inputtab3}>
              <TextField
                type="text"
                id="outlined-basic"
                label="Years of Experience"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1, borderRadius: "20px" }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                label="Methodology or model used when coaching"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                label="Challenges/adversities faced as a coache"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />
            </div>
          </div>
          <div className={style.rightabcontent}>
            <div className={style.inputtab2}>
              <TextField
                type="text"
                id="outlined-basic"
                label="Coaching style"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                label="Perfect Client"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                label=" Way stay current in coaching as a profession"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />
            </div>
          </div>
        </div>
        <div className={style.formbutton}>
          <button>Update</button>
        </div>
      </div>
    </>
  );
};

export default SetTab3;
